/*
|-------------------------------------------------------------------------
| Spring Court 
| App style
| version 1.1
||--------------------------------------------------------------------------
| 1.0 Font Import
| 2.0 Base
| 3.0 Header
| 2.0 Base
| 2.0 Base
|
*/

/*
|--------------------------------------------------------------------------
|  1.0 Font Import
|  
|--------------------------------------------------------------------------
|
| 
|
*/

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');
@font-face {
  font-family: 'Aller';
  font-style: normal;
  font-weight: normal;
  src: local('Aller'), url('../fonts/Aller_Rg.woff') format('woff');
}

@font-face {
  font-family: 'Aller Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Aller Italic'), url('../fonts/Aller_It.woff') format('woff');
}

@font-face {
  font-family: 'Aller Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Aller Bold'), url('../fonts/Aller_Bd.woff') format('woff');
}

@font-face {
  font-family: 'Aller Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Aller Bold Italic'),
    url('../fonts/Aller_BdIt.woff') format('woff');
}

@font-face {
  font-family: 'Raiders';
  src: url('../fonts/Raiders.ttf.woff') format('woff'),
    url('../fonts/Raiders.ttf.svg#Raiders') format('svg'),
    url('../fonts/Raiders.ttf.eot'),
    url('../fonts/Raiders.ttf.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Azkia';
  src: url('../fonts/Azkia_demo.eot?') format('eot'),
    url('../fonts/Azkia_demo.woff') format('woff'),
    url('../fonts/Azkia_demo.ttf') format('truetype'),
    url('../fonts/Azkia_demo.svg#Azkia') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Gothem Black Regular */

@font-face {
  font-family: 'Gotham Black';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Black'),
    url('../fonts/Gotham Black Regular.woff') format('woff');
}

@font-face {
  font-family: 'GothamLight';
  src: url('../fonts/GothamLight.eot');
  src: local('GothamLight'), url('../fonts/GothamLight.woff') format('woff'),
    url('../fonts/GothamLight.ttf') format('truetype');
}

/*
|--------------------------------------------------------------------------
|  2.0 Base
|  
|--------------------------------------------------------------------------
|
| 
|
*/

html,
body {
  width: 100%;
  height: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: disc;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* #Basic Styles
================================================== */

body {
  -webkit-font-smoothing: antialiased;
  /* Fix for webkit rendering */
  -webkit-text-size-adjust: 100%;
}

/* #Typography
================================================== */

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-weight: inherit;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  text-decoration: none;
}

em,
i {
  font-style: italic;
}

b,
strong {
  font-weight: 700;
}

big,
.big {
  font-size: 110%;
  line-height: 180%;
}

dt {
  font-weight: 700;
}

/* #Links
================================================== */

a,
a:visited,
a:focus {
  text-decoration: none;
  outline: 0;
}

a:hover {
  text-decoration: underline;
}

p a,
p a:visited {
  line-height: inherit;
}

/* #Images
================================================== */

img.scale-with-grid,
img {
  max-width: 100%;
  height: auto;
}

.google-map img {
  max-width: none !important;
}

iframe {
  max-width: 100%;
}

body {
  font-family: 'Aller';
  font-weight: 200;
  font-size: 1rem;
  color: #363636;
}

a {
  color: #767676;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

a:hover,
a:focus {
  color: #a9001c;
}

button:focus {
  outline: none;
}

hr {
  max-width: 100px;
  margin: 25px auto 0;
  border-width: 1px;
  border-color: rgba(34, 34, 34, 0.1);
}

hr.light {
  border-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 1px;
  font-weight: 600;
}

p {
  font-size: 1rem;
  line-height: 1.5;
}

.font-azakie {
  font-family: 'Azkia';
}

.font-aller {
  font-family: 'Aller';
}

.font-raiders {
  font-family: 'Raiders';
}

.font-gothamlight {
  font-family: 'GothamLight';
  font-weight: 300;
}

.font-opnsns {
  font-family: 'Open Sans', sans-serif;
}

h1.font-raiders {
  text-transform: uppercase;
}

h2.font-raiders {
  text-transform: uppercase;
}

section {
  padding: 45px 0;
}

.form-control {
  height: 52px;
  border: 1px solid #e2e2e2;
  box-shadow: none;
  -webkit-box-shadow: none;
  background: #fff;
  font-size: 1rem;
  border-radius: 0;
  color: #615f5f;
  font-family: 'Open Sans', sans-serif;
}

.bg-primary {
  /*background-image: url(../img/paper-texture-bg.jpg); */
  background-color: #ffffff !important;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.form-group {
  position: relative;
}

.mobile-menu {
  display: none;
}

.mobile-menu-close {
  display: none;
}

.mbnav-overlay {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: opacity 0.3s ease 0s;
  left: 0;
  bottom: 0;
}

.bg-secondary {
  background: #ffffff;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ffffff),
    to(#ffffff)
  );
  background: linear-gradient(#ffffff, #ffffff);
}

.text-primary {
  color: #fdcc52;
}

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.btn {
  border-radius: 0px;
  font-size: 1rem;
  font-family: 'Aller Bold';
  text-transform: uppercase;
  border: none;
  box-shadow: none;
  padding: 0.375rem 1.75rem;
  letter-spacing: 1px;
}

.btn-xl {
  font-size: 16px;
  padding: 8px 20px;
}

.btn-primary {
  color: #6f6f6f;
  background-color: inherit;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
  color: #6f6f6f !important;
  background-color: inherit !important;
  border: none !important;
  box-shadow: none !important;
  text-decoration: underline;
}

.btn-secondary {
  background-color: #181818;
  color: #ffffff;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active {
  background-color: #181818;
  color: #ffffff;
}

.header-ordernow-btn {
  position: relative;
}

/*
|--------------------------------------------------------------------------
|  3.0 Layout
|  
|--------------------------------------------------------------------------
|
| 
|
*/

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1270px;
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1470px;
  }
}

/*
|--------------------------------------------------------------------------
|  4.0 Header
|  
|--------------------------------------------------------------------------
|
| 
|
*/

header.main-header {
  box-shadow: 1px -4px 8px #b1acac;
}

.top-header {
  background: #181818;
  width: 100%;
  float: left;
  height: 45px;
}

.top-header-left {
  float: left;
  width: calc(100% - 200px);
  color: #ffffff;
}

.top-header .inner-header-left p {
  font-family: 'Aller Bold';
  padding: 0.75rem;
  margin: 0px;
}

.top-header-account {
  padding: 0.6rem 0;
}

.inner-header-account {
  display: inline-block;
  margin-right: 5px;
}

.inner-header-account:not(:first-child) {
  border-left: 1px solid #767676;
  padding-left: 10px;
}

.top-nav-right > li {
  display: inline-block;
}

.main-menu-block {
  padding: 1rem 0rem;
}

#mainNav {
  border-color: rgba(34, 34, 34, 0.05);
  background-color: white;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  font-weight: 200;
  letter-spacing: 1px;
  float: left;
  width: 100%;
}

.menu-section {
  position: relative;
}

#mainNav .navbar-brand {
  /* width: 300px;
  height: 80px;  
  position: relative; 
  float: left;
  padding: 0;
  margin: 0;*/
}

#mainNav .navbar {
  padding: 0.5rem 0rem;
}

#mainNav .navbar-toggler {
  font-size: 1.5rem;
  color: #ffffff;
  background: #a9001c;
  border-radius: 0px;
  border: 0px;
}

#mainNav .navbar-nav > li {
  margin-left: 0px;
  line-height: 2.8rem;
}

#mainNav .navbar-nav > li > a {
  font-size: 0.8rem;
  font-family: 'Aller Bold';
  text-transform: uppercase;
}

#mainNav .navbar-nav > li > a.active {
  color: #e74c3c !important;
  background-color: transparent;
}

#mainNav .navbar-nav > li > a.active:hover {
  background-color: transparent;
}

#mainNav .navbar-nav > li > a,
#mainNav .navbar-nav > li > a:focus {
  color: #222222;
}

#mainNav .navbar-nav > li > a:hover,
#mainNav .navbar-nav > li > a:focus:hover {
  color: #fdcc52;
}

#mainNav .navbar-nav > li > .dropdown-menu {
  text-transform: uppercase;
  font-family: 'Aller Bold';
  min-width: 14rem;
}

#mainNav .dropdown-item.active,
#mainNav .dropdown-item:active {
  color: #767676;
  text-decoration: none;
  background-color: inherit;
}

@media (max-width: 1500px) {
  #mainNav .navbar-nav > li > {
    font-size: 0.8rem;
  }
}

@media (min-width: 992px) {
  #mainNav {
    border-color: transparent;
    background-color: #ffffff;
    height: 88px;
  }
  #mainNav .navbar-brand {
    color: fade(white, 70%);
  }
  #mainNav .navbar-nav > li > a,
  #mainNav .navbar-nav > li > a:focus {
    color: #767676;
    border-bottom: 2px solid #ffffff;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  #mainNav .navbar-nav > li > a:hover,
  #mainNav .navbar-nav > li > a:focus:hover {
    color: #000000;
    /*border-bottom: 2px solid #A9001C;*/
  }
  #mainNav.navbar-shrink {
    border-color: rgba(34, 34, 34, 0.1);
  }
  #mainNav.navbar-shrink .navbar-brand {
    color: #222222;
  }
  #mainNav.navbar-shrink .navbar-brand:hover,
  #mainNav.navbar-shrink .navbar-brand:focus {
    color: #fdcc52;
  }
  #mainNav.navbar-shrink .navbar-nav > li > a,
  #mainNav.navbar-shrink .navbar-nav > li > a:focus {
    color: #222222;
  }
  #mainNav.navbar-shrink .navbar-nav > li > a:hover,
  #mainNav.navbar-shrink .navbar-nav > li > a:focus:hover {
    color: #fdcc52;
  }
}

.mainmenu-right {
  position: relative;
  padding: 1.6rem 0px 1rem 0px;
}

.mainmenu-right a {
  text-transform: uppercase;
  font-size: 0.9rem;
  font-family: 'Aller Bold';
  cursor: pointer;
}

.mainmenu-right .order-now-box a.order-option-link {
  display: inline-block;
  margin-right: 5px;
}

.mainmenu-right .order-now-box a.order-option-link:not(:first-child) {
  border-left: 1px solid #767676;
  padding-left: 10px;
}

.mainmenu-right .order-now-box {
  margin-right: 0.8rem;
}

#mainNav .mobile-xl {
  padding: 0px;
}

.search-toggle {
  width: 60px;
  height: auto;
  position: relative;
  cursor: pointer;
}

.search-toggle:after {
  width: 30px;
  height: 30px;
  background: url(../img/search-icon.png) no-repeat;
  background-size: cover;
  content: '';
  display: block;
  position: absolute;
  top: -12px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.outer-search-box {
  position: relative;
}

.searchbox {
  width: 540px;
  position: absolute;
  right: -50px;
  top: 100%;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  z-index: 1;
}

.searchbox.open {
  opacity: 1;
  visibility: visible;
  top: 30px;
  right: -15px;
  height: 54px;
  pointer-events: auto;
}

.searchbox:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 16px solid #fff;
  position: absolute;
  top: -14px;
  right: 24px;
}

.searchbox:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid #ccc;
  position: absolute;
  top: -16px;
  right: 24px;
}

.cart-box a {
  display: block;
}

.cart-box .cart-icon-bag {
  position: relative;
  top: -4px;
}

.cart-box .cart-icon-bag img {
  max-width: 90%;
}

.cart-box .cart-icon-bag .number {
  position: absolute;
  width: 25px;
  height: 25px;
  color: #ffffff;
  background: #a9001c;
  top: -20px;
  left: 23px;
  border-radius: 50%;
  text-align: center;
  font-size: 0.8rem;
  padding: 6px 4px;
}

/*
|--------------------------------------------------------------------------
|  5.0 Home Page
|  
|--------------------------------------------------------------------------
|
| 
|
*/

section.master-slider {
  padding: 0rem;
}
.main-wrapper section.master-slider .container{ padding: 0; }

.master-slider .mst-slide {
  position: relative;
}

.master-slider .slick-slide .container {
  position: absolute;
  left: 0px;
  right: 0px;
  margin: auto;
  top: 50%;
  margin-top: -50px;
}

.master-slider .slick-slide .bannercaption {
  color: #fff;
  text-align: center;
  margin: 0 auto;
  position: relative;
}

.master-slider .slick-slide .bannercaption h1.font-gothamlight,
.page-header-block h1.font-gothamlight {
  font-family: 'GothamLight';
  text-transform: uppercase;
  position: relative;
  margin-bottom: 1rem;
}

.page-header-block.bg-transparent h1.font-gothamlight {
  margin-bottom: 0rem;
}

.master-slider .slick-slide .bannercaption h1.font-gothamlight:after,
.master-slider .slick-slide .bannercaption h1.font-gothamlight:before,
.page-header-block h1.font-gothamlight:after,
.page-header-block h1.font-gothamlight:before,
.myaccount-form-sec-block h1.font-gothamlight:after,
.myaccount-form-sec-block h1.font-gothamlight:before {
  content: '';
  width: 16%;
  background: #bcbaba;
  height: 1px;
  position: absolute;
}

.page-header-block.bg-transparent h1.font-gothamlight:after,
.page-header-block.bg-transparent h1.font-gothamlight:before,
.myaccount-form-sec-block h1.font-gothamlight:after,
.myaccount-form-sec-block h1.font-gothamlight:before {
  background: #181818;
}

.master-slider .slick-slide .bannercaption h1:after,
.page-header-block h1.font-gothamlight:after,
.myaccount-form-sec-block h1.font-gothamlight:after {
  top: 50%;
  margin-left: 8px;
}

.master-slider .slick-slide .bannercaption h1:before,
.page-header-block h1.font-gothamlight:before,
.myaccount-form-sec-block h1.font-gothamlight:before {
  top: 50%;
  margin-left: -17%;
}

.master-slider ul {
  margin: 0px;
  padding: 0px;
}

.master-slider .mst-slide {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-background-size: cover;
  height: 650px;
}

.master-slider .home-slider .slick-slide img {
  width: 100%;
}

.home-slider .slick-prev {
  left: 0px;
  background: url(../img/previous-icon.png);
  z-index: 1;
  background-size: 100%;
}

.home-slider .slick-next {
  right: 0px;
  background: url(../img/next-icon.png);
  background-size: 100%;
}

.home-about-section {
  /*background: rgba(19, 4, 4, 0.05) url(../img/home-about-bg.png);*/
  background-repeat: no-repeat;
  background-position: center;
}

.about-slider-block {
  float: left;
  width: 100%;
  box-shadow: 4px 3px 8px -3px rgba(2, 2, 2, 0.47);
}

.about-slider-block .bx-wrapper {
  border: 0px;
  margin: 0px;
}

.about-slider-block .bx-controls.bx-has-pager {
  top: -41px;
  position: relative;
}

.about-slider-block .slick-slide img {
  display: inherit;
}

.about-slider-block .slick-slide img {
  width: 100% !important;
  height: auto !important;
}

.home-about-section .btn,
.home-dinein-section .btn {
  padding: 0px;
}

/* Dots */

.about-slider-block .slick-dots {
  position: absolute;
  bottom: 30px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.about-slider-block .slick-dots li {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 2px;
  padding: 0;
  cursor: pointer;
}

.about-slider-block .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 15px;
  height: 15px;
  padding: 2px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.about-slider-block .slick-dots li button:hover,
.about-slider-block .slick-dots li button:focus {
  outline: none;
}

.about-slider-block .slick-dots li button:hover:before,
.about-slider-block .slick-dots li button:focus:before {
  opacity: 1;
}

.about-slider-block .slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  text-align: center;
  opacity: 0.65;
  background: #605b5d;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.about-slider-block .slick-dots li.slick-active button:before {
  opacity: 0.75;
  background: #a9001c;
}

.home-about-section .left-colm.content-block,
.home-dinein-section .right-colm .content-block {
  text-align: right;
}

.home-dinein-section .container-fluid {
  padding: 0px;
}

.home-dinein-section .container-fluid .row {
  margin: 0px;
}

/*.home-dinein-section .banner-block {
   background: #A9001C;
    -webkit-box-shadow: 5px 1px 5px 1px rgba(2, 2, 2, 0.47);
    box-shadow: 5px 1px 5px 1px rgba(2, 2, 2, 0.47);
    padding: 0rem 0rem 0rem 0rem;
}

.home-dinein-section .banner-block img {
    top: 1rem;
    left: -1rem;
    position: relative;
}
*/

.home-dinein-section .about-slider-block {
  box-shadow: none;
}

.home-featured-dishes-section {
  overflow: hidden;
  margin-bottom: 2rem;
}

.sprct-feature-item-box {
  position: relative;
  text-align: center;
  /*border: 0.375rem solid #A9001C*/
}

.sprct-feature-item-box .box-content,
.sprct-feature-item-box:after,
.sprct-feature-item-box:before {
  position: absolute;
  left: 7%;
  right: 7%;
  transition: all 0.3s;
}

.sprct-feature-item-box:after,
.sprct-feature-item-box:before {
  display: block;
  background: rgba(0, 0, 0, 0.3);
  top: 10%;
  bottom: 10%;
  z-index: 1;
  transform: scale(0, 1);
}

.sprct-feature-item-box:after {
  top: 10.8%;
  bottom: 10.8%;
  transform: scale(1, 0);
}

.sprct-feature-item-box:hover:after,
.sprct-feature-item-box:hover:before {
  transform: scale(1);
  animation: animate 1.5s;
}

.sprct-feature-item-box:hover:before {
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
}

.sprct-feature-item-box:hover img {
  transform: scale(1);
  filter: blur(0px);
  -moz-filter: blur(0px);
  -webkit-filter: blur(0px);
}

.sprct-feature-item-box .box-content {
  padding: 4rem 0.5rem;
  top: 0;
  bottom: 0;
  opacity: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.sprct-feature-item-box:hover .box-content {
  background: rgba(33, 27, 27, 0.48);
  opacity: 1;
  transition: all 0.3s;
}

.sprct-feature-item-box .title {
  font-size: 1rem;
  font-family: 'Aller Bold';
  color: #ffffff;
  margin: 0;
  position: relative;
  top: 0;
  transition: all 1s ease 10ms;
}

.sprct-feature-item-box .title:after {
  content: '';
  width: 0;
  height: 1px;
  background: #ffffff;
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 1s ease 0s;
}

.sprct-feature-item-box:hover .title:after {
  width: 80%;
  transition: all 1s ease 0.8s;
}

.sprct-feature-item-box p {
  font-size: 1rem;
  color: #ffffff;
}

.sprct-feature-item-box .post {
  display: block;
  margin: 0 0 5px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}

.sprct-feature-item-box .item-info-action {
  font-size: 14px;
  color: #fff;
  margin: 1rem 0 20px;
}

.sprct-feature-item-box .item-info-action .btn-primary {
  color: #ffffff;
}

.sprct-feature-item-box .item-info-action .btn-primary:hover,
.sprct-feature-item-box .item-info-action .btn-primary:focus,
.sprct-feature-item-box .item-info-action .btn-primary:active,
.sprct-feature-item-box .item-info-action .btn-primary.active {
  color: #ffffff !important;
}

@keyframes animate {
  0%,
  100% {
    opacity: 1;
  }
}

@keyframes animate-hover {
  0% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3);
  }
  50% {
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  }
}

.header-line {
  text-align: center;
}

.header-line span {
  display: inline-block;
  position: relative;
}

.header-line span:before,
.header-line span:after {
  content: '';
  position: absolute;
  height: 1px;
  border-top: 1px solid black;
  top: 24px;
  width: 90%;
}

.header-line span:before {
  right: 100%;
  margin-right: 15px;
}

.header-line span:after {
  left: 100%;
  margin-left: 15px;
}

.home-featured-dishes-section .slick-initialized .slick-slide {
  display: block;
  padding: 0.7rem;
}

.home-featured-dishes-section .featured-dishes-slider {
  padding: 0rem 3rem;
}

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 62px;
  height: 77px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}

.featured-dishes-slider .slick-prev {
  left: -45px;
  background: #ffffff url(../img/previous-icon.png);
}

.featured-dishes-slider .slick-next {
  right: -52px;
  background: #ffffff url(../img/next-icon.png);
}

.menu-category-block .slick-prev {
  left: 0;
  background: #ffffff url(../img/previous-icon.png);
  z-index: 55;
  height: 48px;
  background-position: center;
  width: 48px;
  background-size: 40px;
}

.menu-category-block .slick-next {
  right: 0;
  background: #ffffff url(../img/next-icon.png);
  height: 48px;
  background-position: center;
  width: 48px;
  background-size: 40px;
}

.menu-category-block .slick-slider {
  padding: 0rem 3rem;
  height: 48px;
}

.menu-category-block .slick-initialized .slick-slide {
  border: 1px solid #000000;
}

.menu-category-block .slick-list,
.menu-category-block .slick-slider,
.menu-category-block .slick-track {
  display: flex;
}

.menu-category-block button:focus {
  outline: none;
}

.bakery_row_fixed {
  position: fixed;
  top: 131px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
  margin: auto;
  text-align: center;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  max-width: 1440px;
}

section.inner-page-header {
  background-color: #e3e3e3;
  overflow: hidden;
}

.page-header-block {
  padding: 3rem;
  color: #040507;
  text-align: center;
}

.page-header-block.bg-transparent {
  color: #181818;
  text-align: center;
  padding: 3rem 0rem 0rem;
}

.page-header-block h1.font-azakie {
  font-size: 4rem;
}

.spct-menu-list {
  padding: 0rem 2rem;
}

.container.spct-producs-container {
  margin-bottom: 5rem;
}

.spct-menu-list .product-item-colm .card {
  background-color: #fafafa;
  text-align: center;
  border: 1px solid none;
  border-radius: 0px;
  box-shadow: 2px 0px 24px 0px rgba(1, 1, 1, 0.08);
  height: 100%;
}

.spct-menu-list .product-item-colm .card:hover {
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
}

.spct-menu-list .product-item-colm {
  margin-bottom: 1rem;
}

.spct-menu-list .product-item-colm .card-body .card-title,
.spct-menu-list .product-item-colm .card-body .price-tag {
  font-size: 1rem;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
}

.spct-menu-list .product-item-colm .card img,
.spct-menu-list .product-item-colm .card-body .card-title {
  cursor: pointer;
}

.spct-menu-list .product-item-colm .card-body .price-tag {
  font-size: 1.4rem;
  margin-top: 1rem;
}

.spct-menu-list .product-item-colm .card-body .btn.add-to-btn-cart {
  width: 175px;
  margin: 1rem auto;
}

.spct-menu-list .product-item-colm .card-body .menu-set-tag {
  display: block;
  font-weight: 300;
  color: #5b5b5b;
}

.spct-menu-list .menu-category-block a {
  display: block;
  padding: 1rem;
  text-align: center;
  color: #000000;
  font-family: 'GothamLight';
  text-transform: uppercase;
  text-decoration: none;
  height: 100%;
  font-size: 0.9rem;
}

.spct-menu-list .menu-category-block a.active {
  color: #ffffff;
  background: #000000;
}

.menu-category-block {
  margin-bottom: 2rem;
  background: #ffffff;
}

.row.menu-category-block .col {
  padding: 0rem;
  background: white;
  border: 1px solid #000;
}

.product-details {
  background: white;
  margin-bottom: 1rem;
  position: relative;
}

button.product-view-close {
  text-transform: uppercase;
  outline: 0;
  position: absolute;
  right: 0;
  border: 0px;
  text-indent: -9999px;
  width: 36px;
  height: 36px;
  background: #000000 url(../img/cross-symbol.PNG) center no-repeat;
  z-index: 1;
}

.product-details .row.row-of-product-slider {
  margin: 0px;
  background: url(../img/product-details-slider-bg.png) top center no-repeat;
  background-size: contain;
}

.product-slider-block {
  margin-top: 2.5rem;
}

.product-more-slider.slick-initialized .product-slide {
  border: 3px solid #161412;
}

.product-more-slider.slick-initialized .product-slide img {
  width: 100%;
}

.product-more-slider .slick-prev {
  left: 8px;
  background: #e8e5df url(../img/previous-icon.png);
  z-index: 1;
}

.product-more-slider .slick-next {
  right: 8px;
  background: #e8e5df url(../img/next-icon.png);
}

.product-details .product-info-colm {
  padding: 3.5rem 2rem 1rem 2rem;
  color: #000000;
}

.product-details .product-info-colm .card,
.product-details .product-info-colm .card-header,
.product-details .product-info-colm .card-footer {
  border: 0px;
  border-radius: 0px;
}

.product-details .product-info-colm .card-header {
  padding: 0.75rem 0rem;
}

.product-details .product-info-colm.no-gallery .card-header {
  padding: 2.75rem 3rem;
}

.product-review-line .fas.fa-star {
  color: #d7cbb9;
  font-size: 0.9rem;
}

.product-review-line .fas.fa-star.checked {
  color: #72624f;
}

.product-details .product-info-colm .card-body {
  border: 0px;
  background-color: #fbfbfb;
  color: #000000;
  padding: 2.5rem 1.5rem;
}

.product-details .card-header h5 {
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.product-details .product-review-block {
  margin-left: 3rem;
}

.product-details .card-header p {
  font-family: 'Open Sans', sans-serif;
}

.product-review-line .review-link {
  font-size: 0.9rem;
  color: #5b5b5b;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  text-decoration: underline;
}

.chef-pr-food {
  position: relative;
  float: left;
  width: 32px;
  height: 20px;
}

.chef-pr-food::before {
  content: '';
  width: 20px;
  height: 20px;
  background-image: url(../img/chef-icon.png);
  position: absolute;
  background-repeat: no-repeat;
  top: 4px;
}

.choosen-product-options-colm {
  padding: 1rem 2rem;
}

.product-choosen-item {
  margin-bottom: 1rem;
}

/* The spct-radio */

.spct-radio {
  display: block;
  position: relative;
  padding-right: 35px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 2.5;
  color: #72624f;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

/* Hide the browser's default radio button */

.spct-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

.checkmark {
  position: absolute;
  top: 4px;
  right: 4px;
  height: 28px;
  width: 28px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #e2e1e1;
}

/* On mouse-over, add a grey background color */

.spct-radio:hover input ~ .checkmark {
  background-color: #ffffff;
}

/* When the radio button is checked, add a blue background */

.spct-radio input:checked ~ .checkmark {
  background-color: #ffffff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.spct-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */

.spct-radio .checkmark:after {
  top: 0px;
  left: 0px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: #a9001c;
  background: url(../img/radio-check.png);
}

.choosen-product-options-colm h5 {
  font-weight: bold;
  font-size: 1rem;
  color: #72624f;
  display: inline-block;
  margin-bottom: 0rem;
  background: #fbfbfb;
  position: relative;
  z-index: 1;
}

.product-chosen-header {
  position: relative;
  margin-bottom: 1rem;
}

.product-chosen-header span {
  width: 100%;
  background: #ecdfcc;
  height: 2px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.chosen-adn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  /* flex-wrap: wrap; */
  width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0.5rem;
  font-family: 'Open Sans', sans-serif;
}

.chosen-adn-left span {
  font-size: 1rem;
  font-weight: 700;
  color: #72624f;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  background: #fbfbfb;
  z-index: 1;
  padding-right: 10px;
}

.chosen-adn-right {
  position: relative;
}

.qty-bx span {
  position: absolute;
  width: 38px;
  height: 38px;
  line-height: 36px;
  display: block;
  top: 0;
  cursor: pointer;
  color: #fff;
  font-size: 30px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.combo-input-label {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  border: none;
  color: #010101;
  font-size: 13px;
  text-align: center;
  margin: 0;
  padding: 2px;
  font-size: 19px;
  font-weight: 700;
  line-height: normal;
  line-height: 34px;
}

.qty-bx {
  width: 135px;
  height: 38px;
  position: relative;
  padding: 0 38px;
  display: inline-block;
  overflow: hidden;
}

.qty-bx input,
.qty-bx span {
  text-align: center;
  font-weight: 700;
}

.qty-bx .qty-minus {
  background: #a9001c;
  left: 0;
  border-radius: 5px 0 0 5px;
}

.qty-bx .qty-plus {
  background: #a9001c;
  right: 0;
  border-radius: 0 5px 5px 0;
}

.qty-bx span:hover {
  background: #a9001c;
}

.qty-bx input {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  border: none;
  color: #010101;
  font-size: 13px;
  margin: 0;
  padding: 2px;
  font-size: 19px;
  outline: 0;
}

.prd-chosen-sub-col {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 576px;
  margin: 0 auto;
  background: #e6e3e3;
  border: 1px solid #f9f9f9;
}

.prd-chosen-sub-col .alert {
  margin: 0;
  width: 100%;
}

.prd-chosen-sub-item-left {
  width: 32.11805555555556%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.prd-chosen-sub-item-right {
  width: 66.64705882352942%;
}

.prd-chosen-sub-item-right button {
  border: 0;
  padding: 12.5px 15px;
  max-width: 391px;
  background: #181818;
  width: 100%;
  color: #fff;
  font-family: 'Aller Bold';
  font-size: 16px;
  text-transform: uppercase;
  outline: 0;
}

.prd-chosen-sub-item-right button:hover {
  background: #a9001c;
}

.prd-chosen-sub-item-right.prd-chosen-item-full {
  width: 100%;
}

.prd-chosen-sub-item-right.prd-chosen-item-full button {
  max-width: 100%;
  background: #a9001c;
}

.prd-chosen-sub-item-right.prd-chosen-item-full button:hover {
  background: #000000;
  color: #fff;
}

.prd-chosen-sub-item-left .qty-bx {
  width: 166px;
  margin: 0.3rem;
}

.prd-chosen-sub-item-left .qty-bx input {
  background: #fff;
  width: 100%;
}

.prd-chosen-sub-item-left .qty-bx .qty-minus {
  background: #a9001c;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.prd-chosen-sub-item-left .qty-bx .qty-plus {
  background: #a9001c;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.prd-chosen-sub-item-left .qty-bx .qty-minus:hover,
.prd-chosen-sub-item-left .qty-bx .qty-plus:hover {
  background: #333;
}

/*
|--------------------------------------------------------------------------
|  4.0 Checkout Page
|  
|--------------------------------------------------------------------------
|
| 
|
*/

.prd-more-item-block {
  background: #ffffff;
}

.checkout-block-header {
  background: #e6e3e3;
  padding: 1rem;
  margin: 0;
}

.prd-more-item-block .prd-more-item-list {
  padding: 3rem 6rem;
}

.prd-more-item-block .prd-more-item-list .card {
  margin-right: 0px;
  border: 0px;
  text-align: center;
  border-radius: 0px;
}

.prd-more-item-list .slick-slider.slick-initialized {
  padding: 0rem 2rem;
}

.prd-more-item-list .slick-list {
  margin: 0 -0.4rem;
}

.prd-more-item-list .slick-slide {
  margin: 0 0.4rem;
}

.prd-more-item-list .slick-track {
  display: flex;
}

.prd-more-item-list .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.prd-more-item-list .card .card-img-top {
  border-radius: 0px;
}

.prd-more-item-list .card .card-body {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end !important;
  font-family: 'Open Sans', sans-serif;
  padding: 0.5rem;
}

.prd-more-item-list .card .card-title {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  display: block;
  width: 100%;
  font-weight: bold;
  font-size: 1rem;
}

.prd-more-item-list .slick-prev {
  left: -45px;
  background: #f5f5f5 url(../img/previous-icon.png);
}

.prd-more-item-list .slick-next {
  right: -45px;
  background: #f5f5f5 url(../img/next-icon.png);
}

.prd-more-item-list .card .card-text {
  color: #666666;
  font-size: 0.9rem;
  width: 100%;
}

.prd-more-item-list .card .prd-price-tag {
  text-align: center;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

.prd-more-item-list .card .prd-price-tag del {
  font-weight: 300;
  font-size: 0.9rem;
  margin-right: 0.5rem;
  position: relative;
  top: -2px;
}

.product-item.card.cheif-fav-food::before {
  content: '';
  height: 1rem;
  width: 1rem;
  position: absolute;
  background-image: url('../img/chef-icon.png');
  right: 0px;
  top: 0px;
  background-color: #dbdbdb;
  background-repeat: no-repeat;
  padding: 13px;
  background-position: center;
}

.breadcrumb {
  background: none;
  font-size: 0.9rem;
  margin-bottom: 0rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  font-family: 'Font Awesome 5 Free';
  content: '\f054';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  font-size: 0.8rem;
}

.self-collection-block,
.order-details-item-block {
  margin: 1rem 0rem;
  background-color: #fbfbfb;
  min-height: 300px;
}

.slfcol-form-block {
  padding: 4.756rem 3.5rem;
}
.slfcol-form-block .syd_merge{ margin-left: -15px; margin-right: -15px;}
.slfcol-form-block .syd_date{ width: 50%; padding: 0 15px;}
.slfcol-form-block .syd_date .react-datepicker-wrapper{ display: block;  width: auto;}
.slfcol-form-block .syd_time{ width: 50%; padding: 0 15px;}
.slfcol-form-block .syd_time .react-datepicker-wrapper{ display: block; width: auto;}

.react-datepicker__time-container,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100px;
}

.slfcol-form-block .media {
  margin-bottom: 3rem;
}

.slfcol-form-block .media-body h6,
.slfcol-form-block .media-body .outlet-info,
.slfcol-form-block .special-instruction-block h6,
.cart-img,
.cart-info h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

.slfcol-form-block .media-body h6 {
  text-transform: uppercase;
}

.slfcol-form-block .special-instruction-block h6 {
  padding-left: 2.9rem;
}

.slfcol-form-block .react-datepicker-wrapper .collect-time-picker {
  margin-left: 0.5rem;
}

.special-instruction-block h6 {
  text-transform: uppercase;
}

.cart-row {
  padding: 18px 35px 5px 15px;
  border-bottom: 1px solid #e4e3e3;
  position: relative;
}

.chekout-cart-bar .cart-left {
  display: flex;
}

.cart-img,
.cart-info {
  display: inline-block;
  vertical-align: top;
}

.cart-img {
  width: 110px;
  margin-right: 10px;
  height: 66px;
  line-height: 66px;
  overflow: hidden;
}

.cart-info {
  width: calc(100% - 120px);
}
.cart-info h6{ font-size: 14px; }

.cart-row [class*='col-'] {
  padding-left: 0px;
  padding-right: 0.8rem;
}

.cart-remove {
  background: #f4f3f3 url(../img/cross-symbol-checkout.png) no-repeat center;
  position: absolute;
  top: 18px;
  right: 0;
  width: 21px;
  height: 21px;
}

.cart-price p {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0px;
}

.cart-special-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.8rem;
  color: #a7a4a4;
  font-style: italic;
  margin: 0.5rem 0rem;
  line-height: inherit;
}

.cart-items-block {
  padding: 1.9rem 3rem;
}

.calctrcart-footer {
  padding: 1.5rem 0;
}

.calctrcart-footer p {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

.calctrcart-footer .col-4.text-right {
  font-family: Open Sans, sans-serif;
  font-weight: 700;
  font-size: 1rem;
}

.calctrcart-footer-totrow p,
.calctrcart-footer-totrow .col-4.text-right {
  font-size: 1.5em;
}

.quto-download-block {
  background: #e6e3e3;
  padding: 0.5rem;
}

.quto-download-block h6 {
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.8rem;
  margin-bottom: 0px;
}

.quto-download-block img {
  position: relative;
  top: -3px;
  right: 10px;
}

.cart-row-header {
  padding: 0.3rem 0rem;
}

.cart-row-header h6 {
  font-family: 'Aller Bold';
  margin-bottom: 0;
}

.redeem-promocode-row.row h4 {
  font-family: 'Raiders';
  font-weight: bold;
  font-size: 1.5rem;
  position: relative;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.redeem-promocode-row.row .badge {
  background-color: #1c1c1c;
  position: absolute;
  right: 0;
  padding: 0.6rem 1.6rem;
  top: 0px;
  border-radius: 0.7rem;
  text-transform: capitalize;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 0.9rem;
}

.redeem-promocode-row .left-col-of-redeem-points {
  background-color: #f3e67b;
  padding: 2rem;
  border-radius: 1rem;
}

.redeem-promocode-row .help-text {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.redeem-promocode-row .right-col-of-promo-code {
  background-color: #ed5e65;
  padding: 2rem;
  border-radius: 1rem;
  color: #ffffff;
}

.redeem-promocode-row .input-group-append .btn {
  font-family: 'Aller Bold';
  padding: 0rem 2rem;
  border: 0;
  border-radius: 0px;
  font-size: 1rem;
  background-color: #1c1c1c;
}

.redeem-promocode-row .input-group > .form-control {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}

.checkout-payment-actions {
  width: 100%;
  background-color: #ffffff;
  margin: 1rem 0rem 4rem 0rem;
}

.payment-types-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
}

.payment-types-block .form-group {
  display: flex;
  flex-wrap: wrap;
}

.checkout-payment-actions .custom-control {
  border: 2px solid #dbdbdb;
  background: #ffffff;
  border-radius: 0.5rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-left: 0.9rem;
  height: 4rem;
  padding-left: 0;
}

.checkout-payment-actions .custom-control-label::before {
  position: absolute;
  top: 1.28rem;
  left: 1rem;
  display: block;
  width: 1.3rem;
  height: 1.3rem;
  pointer-events: none;
  content: '';
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.checkout-payment-actions .custom-control-label::after {
  position: absolute;
  top: 1.28rem;
  left: 1rem;
  display: block;
  width: 1.3rem;
  height: 1.3rem;
  background: none;
}

.checkout-payment-actions .custom-control-input:checked ~ .custom-control {
  color: #fff;
  border: 2px solid #dbdbdb;
  background-color: #1c1c1c;
}

.checkout-payment-actions
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #e6652c;
  background-color: #e6652c;
  font-family: 'Font Awesome 5 Free';
  content: '\f058';
  font-weight: 900;
}

.checkout-payment-actions
  .custom-control-input:checked
  ~ .custom-control-label::after {
  color: #e6652c;
  border-color: #e6652c;
  background-color: inherit;
  font-family: 'Font Awesome 5 Free';
  content: '\F058';
  font-weight: 900;
  line-height: 1;
  font-size: 1.3rem;
  outline: 0;
}

.checkout-payment-actions .custom-control-label {
  padding: 0rem 1.5rem 0rem 2.7rem;
  height: 3.8rem;
  font-size: 1rem;
  line-height: 3.8rem;
}

.checkout-btn-block.row .btn {
  width: 100%;
  text-align: center;
  height: calc(2.9rem + 3px);
  color: #ffffff;
  line-height: 2.5;
}

.checkout-btn-block.row .btn.btn-back,
.checkout-btn-block.row .btn.btn-back:hover {
  background-color: #1c1c1c !important;
  color: #ffffff;
}

.modal-content {
  border: 1px solid #181818;
  border-radius: 0rem;
}

.modal-header {
  border-bottom: 0px solid #dee2e6;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  padding: 0rem;
}

.modal-footer {
  border-top: 0px solid #dee2e6;
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.modal .close {
  padding: 0.3rem 0.8rem;
  margin: 0rem 0rem 0rem auto;
  background: #512437 url(../img/cross-symbol.PNG) center no-repeat;
  width: 2rem;
  height: 2rem;
  opacity: 1;
  position: absolute;
  right: 0;
  z-index: 999;
}

.bg-Camelot {
  background-color: #a9001c !important;
}

.bg-Black {
  background-color: #1e1d1d !important;
}

.subscribe-now-modal {
  color: #ffffff;
  text-align: center;
}

.subscribe-now-modal .form-block {
  padding-top: 2rem;
}

.subscribe-now-modal .form-block h1,
.subscribe-now-modal .form-block .help-text {
  text-transform: uppercase;
  text-align: center;
}

.subscribe-now-modal .form-block .btn {
  font-family: 'Aller Bold';
}

.subscribe-now-modal .form-block .input-group {
  border: 1px solid #fff;
}

.subscribe-now-modal .form-block .help-text {
  margin-top: 1rem;
}

.special-promo-modal {
  background: #512437 url(../img/promo-modal-bg.png) center no-repeat;
}

.special-promo-modal .form-block {
  padding: 2rem 4rem;
}

.special-promo-modal .modal-header {
  z-index: 1;
}

.special-promo-modal .form-block .help-text {
  margin: 0rem;
  margin-bottom: 0.6rem;
  font-size: 0.8rem;
}

.order-option-block {
  text-align: center;
  color: #ffffff;
  padding-bottom: 3rem;
}

.order-option-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  padding-left: 0;
  cursor: pointer;
}

.order-option-list li.order-option-tem.delivery .order-icon {
  display: block;
  background: url(../img/ico-delivery.png) no-repeat;
  width: 70px;
  height: 50px;
  text-align: center;
  margin: 0.3rem auto;
  background-position: 50% !important;
}

.order-option-list li.order-option-tem.delivery .btn.active .order-icon,
.order-option-list li.order-option-tem.delivery .btn:hover .order-icon {
  background: url(../img/ico-delivery-white.png) no-repeat;
}

.order-option-list .order-icon {
  display: block;
  background: url(../img/dinein-icon-black.PNG) no-repeat;
  width: 88px;
  height: 63px;
  text-align: center;
  margin: 0.3rem auto;
  background-position: 50% !important;
}

.order-option-list .btn.active .order-icon,
.order-option-list .btn:hover .order-icon {
  background: url(../img/dinein-icon.png) no-repeat;
}

.order-option-list li {
  max-width: 234px;
  width: 100%;
  border-radius: 1rem;
  margin-left: 13px;
}

.order-option-list li .btn {
  padding: 2rem;
  width: 100%;
  border-radius: 1rem;
  font-family: 'Aller';
  text-transform: capitalize;
  color: #000000;
}

.order-option-list li.dine-in {
  border: 2px solid #b64a77;
}

.order-option-list li.self-collection {
  border: 2px solid #b64a77;
}

.order-option-list li.self-collection .order-icon {
  background: url(../img/take-away.png) no-repeat center;
}

.order-option-list li.self-collection .btn.active .order-icon,
.order-option-list li.self-collection .btn:hover .order-icon {
  background: url(../img/take-away-white.png) no-repeat center;
}

.delivery-address-block {
  text-align: center;
  color: #ffffff;
}

.delivery-datetime-modal .modal-body {
  padding: 0px;
}

.delivery-datetime-modal .modal-body .delivery-address-block {
  padding: 2.8rem 2rem;
}

.delivery-datetime-modal .form-block {
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center !important;
  align-items: center !important;
  padding: 1rem;
}

.delivery-datetime-form-block .btn {
  padding: 0.875rem 1.75rem;
  font-family: 'Aller Bold';
}

.delivery-datetime-form-block .form-control {
  width: calc(100% - 0.2rem);
  background-image: url(../img/down-arrow.png);
  background-position: right 0.6rem center;
  background-repeat: no-repeat;
}

.delivery-datetime-form-block .form-control.collect-time-picker {
  margin-left: 0.2rem;
}

.btn-checkout,
.btn-checkout:hover,
.btn-checkout:focus,
.btn-checkout:active {
  background-color: #a9001c !important;
  color: #fff !important;
}

.mobile-header-navigation {
  display: none;
}

.header-cart-dropdown {
  background: #fff;
  -webkit-box-shadow: 4px 0 13px 0 rgba(0, 0, 0, 0.35);
  box-shadow: 4px 0 13px 0 rgba(0, 0, 0, 0.35);
  right: 0rem;
  width: 550px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  z-index: 999;
  top: 96%;
}

.header-cart-dropdown:after {
  content: '';
  position: absolute;
  top: -19px;
  right: 7px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 20px solid #000;
}

.header-cart-dropdown.show-cart {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.header-cart-dropdown .cart-row-header {
  background-color: #181818;
  border: 1px solid #a9001c;
  padding: 17.5px 20px 17.5px 35px;
  color: #fff;
}

.header-cart-scrollarea {
  max-height: 388px;
  overflow-y: auto;
}

@media (max-width: 680px) {
  .header-cart-dropdown {
    max-width: 100%;
    width: 100%;
    z-index: 11;
    right: 0;
    top: -100%;
  }
}

.cart-body {
  padding: 1rem;
}

.header-cart-dropdown .cart-row {
  padding: 18px 35px 5px 30px;
}

.header-cart-dropdown .calctrcart-footer {
  padding: 1.5rem 1.7rem 1.5rem 1rem;
}

.cartaction-bottom .btn {
  margin: 0;
  float: left;
  border: 0;
  padding: 1rem;
}

.cartaction-checkout {
  width: 70%;
  font-size: 1.2rem;
  background-color: #a9001c;
  color: #ffffff;
}

.cartaction-price {
  font-size: 1.2rem;
  width: 30%;
  cursor: default;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

.delivery-info {
  background: #f5f5f5;
  padding: 24px 35px;
}

.delivery-info .slfcol-form-block {
  padding: 0rem;
}

.delivery-info .slfcol-form-block .media-body h6 {
  color: #838181;
}

.delivery-info .slfcol-form-block .media {
  margin-bottom: 2rem;
}

.pop-whole {
  position: relative;
}

.pop-whole-lhs {
  position: absolute;
  background: #a9001c;
  padding: 5.5rem 5rem 5.5rem 4rem;
  left: 0;
  right: 0;
  z-index: 2;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #ffffff;
}

.signup-corparateone-popup .inside-popup-rhs,
.signup-corparatetwo-popup .inside-popup-rhs,
.signup-popup .inside-popup-rhs {
  padding: 45px 55px 30px;
}

.inside-popup-rhs {
  float: right;
  max-width: 514px;
  background: #f9f7f7;
  padding: 0px 4rem 0;
  position: relative;
  z-index: 9;
  margin: 0 3rem 0 0;
  width: 100%;
  -webkit-transition: all 0.4s linear;
  -o-transition: 0.4s linear all;
  transition: all 0.4s linear;
}

.pop-whole-lhs-inner {
  text-align: center;
  max-width: 318px;
}

.popup-header {
  padding: 3rem 1.8rem 0rem 1.8rem;
}

.popup-header h4 {
  font-size: 1rem;
}

.popup-body h4 {
  text-transform: uppercase;
  margin: 0 0 11px 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #0e0e0e;
}

.modal-content.bg-transparent {
  background: none;
  border: 0px;
}

.or-seperator {
  position: relative;
  padding: 12px 0 8px 0;
  line-height: 1;
}

.or-seperator span {
  padding: 0 10px;
  background: #f9f7f7;
  display: inline-block;
  z-index: 1;
  position: relative;
}

.or-seperator:after {
  height: 1px;
  width: 100%;
  content: '';
  display: block;
  background: #0e0e0e;
  position: absolute;
  top: 55%;
  left: 0;
}

.btn.fb-button {
  background: #2d4486;
  text-transform: inherit;
  color: #fff;
  font-size: 1rem;
  line-height: 1;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 15px 37px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  margin-bottom: 0.8rem;
}

.btn.fb-button:hover {
  background: #838181;
  top: 0;
}

.btn.fb-button i {
  margin: 0 12px 0 0;
}

.popup-header h4 img {
  margin: 1rem;
}

.popup-body .custom_checkbox {
  width: 100%;
  margin: 7px 0 9px;
}

.custom_checkbox {
  position: relative;
  display: inline-block;
  line-height: 25px;
}

.popup-body .custom_checkbox input {
  border: 1px solid #e2e2e2;
  width: 27px;
}

.popup-body input {
  margin: 0 0 8px;
  font-size: 16px;
  color: #595656;
}

.custom_checkbox input {
  opacity: 0;
  filter: alpha(opacity=0);
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  width: 100%;
  position: absolute;
  cursor: pointer;
}

.custom_checkbox span {
  position: relative;
  padding: 0 0 0 39px;
  pointer-events: none;
  color: #202963;
  display: block;
}

.popup-body .custom_checkbox input[type='checkbox']:checked + span:before {
  background-position: -30px -186px;
}

.custom_checkbox span:before {
  content: '';
  background: #fff;
  position: absolute;
  width: 28px;
  height: 28px;
  border: 1px solid #ecdfcc;
  left: 0;
  top: 1px;
  pointer-events: none;
  line-height: 21px;
  border-radius: 50%;
}

.popup-body .custom_checkbox span:before {
  top: 11px;
  width: 27px;
  height: 27px;
  border: 2px solid #e7d8c1;
  border-radius: 2px;
}

.popup-body .custom_checkbox.custom_checkbox_content span:before {
  top: 0;
}

.custom_checkbox input[type='checkbox']:checked + span:before {
  content: '';
  text-rendering: auto;
  text-align: center;
  border-color: transparent;
  line-height: 23px;
  font-family: 'Font Awesome 5 Free';
  content: '\f00c';
  font-weight: 900;
  line-height: 22px;
  font-size: 1.3rem;
}

.popup-body .sign_reg {
  margin-left: 43px;
}

.popup-body .sign_reg p {
  margin-bottom: 9px;
  color: #0e0e0e;
  line-height: 18px;
  font-size: 0.8rem;
  font-weight: 300;
}

.login_pop_sub .btn {
  padding: 12px 22px;
  height: 51px;
  font-size: 1.2rem;
  font-family: 'Aller Bold';
  margin-bottom: 2rem;
}

.two-links {
  padding: 10px 0 12px;
  text-align: center;
}

.two-links a {
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  display: inline-block;
  vertical-align: top;
  position: relative;
  color: #a9001c;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  text-transform: uppercase;
}

.spilter {
  width: 2px;
  height: 15px;
  background: #a9001c;
  display: inline-block;
  content: '';
  vertical-align: top;
  margin: 0px 25px 0;
  position: relative;
  left: 0;
}

.sign-in-modal .login_pop_sub .btn {
  margin-bottom: 0rem;
}

.guest-checkout-link {
  position: absolute;
  right: 0;
  left: 0rem;
}

.guest-checkout-link .btn {
  padding: 1rem;
  font-family: 'Aller';
}

.go-back-link {
  position: absolute;
  left: 1rem;
  top: 1rem;
  color: #c3c3c3;
  text-transform: uppercase;
}

.outlet-list-option-modal .container-fluid {
  background-color: #f8f6f6;
}

.outlet-list-option-modal .modal-header {
  text-align: center;
  color: #ffffff;
  display: block !important;
}

.outlet-list-option-modal .modal-body {
  padding: 0rem;
}

.outlet-list-option-modal .location-find-btn {
  position: relative;
  padding: 1.2rem 1.5rem 1.2rem 3rem;
  max-width: 290px;
  width: 290px;
  border-radius: 10px;
}

.outlet-list-option-modal .location-find-btn:before {
  position: absolute;
  top: 10px;
  left: 2rem;
  width: 36px;
  height: 36px;
  background: url(../img/placeholder.png) no-repeat;
  content: '';
}

.outlet-list-option-modal .outlet-modal-title {
  padding: 2.5rem 1rem 1.5rem 1rem;
}

.outlet-list-option-modal .outlet-modal-title img {
  float: left;
  position: relative;
  left: 3rem;
  top: 1rem;
}

.pickup-location-list .list-group-item {
  padding: 0px;
  margin-bottom: 0.5rem;
  border: 0px;
  border-radius: 1rem;
}

.pickup-location-list .input-group {
  background-color: #f8f8f8;
  display: block;
  margin: 0px 0;
  position: relative;
}

.pickup-location-list .input-group label {
  padding: 1rem;
  width: 100%;
  display: block;
  text-align: left;
  color: #3c454c;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  margin-bottom: 0px;
  border-radius: 10px;
}

.pickup-location-list .input-group label:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: '';
  background-color: #a9001c;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
  width: 100%;
}

.pickup-location-list .input-group input:checked ~ label {
  color: #fff;
}

.pickup-location-list .input-group input:checked ~ label:before {
  -webkit-transform: translate(-50%, -50%) scale3d(56, 56, 1);
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}

.pickup-location-list .input-group input:checked ~ label:after {
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.pickup-location-list .input-group input {
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}

.pickup-location-list {
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  margin-left: -15px;
  height: 443px;
  overflow-y: auto;
}

.outlet-list-block-right,
.outlet-list-block-left {
  color: #595656;
}

.outlet-list-block-left {
  text-align: center;
  padding: 2rem 1rem 2rem 0.5rem;
}

.outlet-list-block-right {
  padding: 2rem;
}

.outlet-list-block-right h3 {
  font-family: 'Aller Bold';
  margin-top: 3rem;
}

.outlet-list-block-right .btn {
  padding: 1rem;
  font-family: 'Aller Bold';
}

.location-btn-block {
  position: relative;
}

.outlet-or-separtor-block {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.outlet-or-separtor-block .or-seperator {
  height: 100%;
}

.outlet-or-separtor-block .or-seperator span {
  padding: 0 5px;
  background: #f9f7f7;
  display: inline-block;
  z-index: 1;
  position: relative;
  top: 38%;
  font-size: 1rem;
}

.outlet-or-separtor-block .or-seperator:after {
  height: 100%;
  width: 1px;
  content: '';
  display: block;
  background: #e5cda6;
  position: absolute;
  top: 0;
  right: 14px;
  left: inherit;
}

.outlet-list-option-modal .go-back-link {
  font-family: 'Aller Bold';
  font-size: 1rem;
  color: #fff;
}

.outlet-list-option-modal .go-back-link:hover {
  color: #fff;
}

.inside-popup-rhs .close {
  padding: 0.3rem 0.8rem;
  margin: 0rem 0rem 0rem auto;
  background: url(../img/cross-symbol-gray.png) center no-repeat;
  width: 3rem;
  height: 3rem;
  opacity: 1;
  position: absolute;
  right: 0;
  z-index: 999;
}

.order-info-block .checkout-block-header {
  background: #1c1c1c;
  color: #ffffff;
}

.order-info-block .order-info {
  background: #985471;
  color: #ffffff;
  padding: 1rem;
}

.order-info-block .order-info p {
  margin-bottom: 0px;
}

.order-info-block .delivery-info .slfcol-form-block .media {
  margin-bottom: 0rem;
}

.order-info-block .cart-row {
  padding: 18px 0px 5px 15px;
}

.check-order-status-btn-block .btn {
  padding: 0.875rem 1.75rem;
}

/*
|--------------------------------------------------------------------------
|  4.0 MY account Page
|  
|--------------------------------------------------------------------------
|
| 
|
*/

.myaccount-dashboard-block {
  background-color: #ffffff;
  margin-bottom: 2.5rem;
}

.myaccount-navbar {
  background: #e6e3e3;
}

.myaccount-navbar .nav-tabs .nav-item .nav-link {
  padding: 1.2rem 0.5rem;
  font-family: 'Aller Bold';
  text-transform: uppercase;
}

.myaccount-navbar .nav-tabs .nav-link.active {
  background: #a9001c;
  color: #ffffff;
}

.myaccount-navbar .nav-tabs .nav-link {
  border: 0px;
  border-radius: 0px;
}

.myaccount-orders-options {
  padding: 2.3rem 0rem 2rem 0rem;
}

.myaccount-orders-block .nav-tabs {
  border-bottom: 4px solid #e9e8e8;
}

.myaccount-orders-block .nav-tabs .nav-item {
  margin-bottom: 0px;
}

.myaccount-orders-block .nav-tabs .nav-link {
  border: 0px;
  font-family: 'Aller Bold';
  text-transform: uppercase;
}

.myaccount-orders-block .nav-tabs .nav-link span {
  border-bottom: 4px solid #f8dcdc;
  padding: 0.4rem 1rem;
}

.myaccount-orders-block .nav-tabs .nav-link.active span {
  border-bottom: 4px solid #a9001c;
}

.myaccount-orders-block .order-info-block .checkout-block-header {
  position: relative;
  float: left;
  width: 100%;
  padding: 0px;
}

.myaccount-orders-block .order-info-block .checkout-block-header .order-status {
  padding: 1rem;
  position: relative;
  right: 0;
  top: 0;
  background-color: #e9cd93;
  font-size: 1.5rem;
}

.myaccount-orders-block .order-info-block .checkout-block-header h4 {
  width: calc(100% - 180px);
  font-weight: bold;
  float: left;
  padding: 0.7rem 1rem;
  margin: 0;
  line-height: 1.4;
}

.order-amount-row {
  background: #000000;
  margin: 0px;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-weight: bold;
}

.order-amount-row p {
  margin-top: 1rem;
  font-size: 1.3rem;
}

.myaccount-orders-block .checkout-btn-block.row .btn.btn-back,
.myaccount-orders-block .checkout-btn-block.row .btn.btn-back:hover {
  background-color: #535353 !important;
}

.myaccount-orders-block .order-info {
  background: #f9f9f9;
  color: #141414;
  padding: 1rem;
  clear: both;
}

.myaccount-orders-block .delivery-info {
  background: #f9f9f9;
  color: #141414;
  padding: 24px 35px;
}

.myaccount-orders-block .order-status.completed {
  background: #535353 !important;
}

.myaccount-orders-block
  .order-info-block
  .checkout-block-header
  .order-status:before {
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  border-left: 30px solid #1c1c1c;
  border-bottom: 56px solid #e9cd93;
  top: 0;
  left: -26px;
}

.myaccount-orders-block
  .order-info-block
  .checkout-block-header
  .order-status.completed:before {
  border-left: 30px solid #1c1c1c;
  border-bottom: 56px solid #535353;
}

.myaccount-orders-block .delivery-info .slfcol-form-block .media-body h6 {
  color: #1c1b1b;
}

.myaccount-orders-block .slfcol-form-block .media-body .outlet-info {
  font-weight: normal;
}

.myaccount-orders-block .delivery-info {
  padding: 0px;
}

.myaccount-orders-block .delivery-info .slfcol-form-block {
  padding: 1rem 2.3rem;
}

.delivery-scotter-location {
  position: relative;
  z-index: 2;
  height: 30px;
  width: 100%;
  margin-bottom: 0.5rem;
}

.delivery-scotter-location::before {
  content: '';
  width: 40px;
  height: 24px;
  left: -15px;
  top: -2px;
  position: absolute;
  background: #f9f9f9 url(../img/scootter-icon.png) no-repeat center;
  z-index: 2;
}

.delivery-scotter-location::after {
  content: '';
  width: 24px;
  height: 24px;
  right: -15px;
  top: -2px;
  position: absolute;
  background: #f9f9f9 url(../img/location-mp-icon.png) no-repeat center;
  z-index: 2;
}

.delivery-scotter-location .white-line {
  display: block;
  width: 100%;
  height: 3px;
  background: white;
  position: relative;
  top: 9px;
  right: 0;
}

.myaccount-orders-block .tab-content {
  padding: 1.5rem 2.5rem;
}

.react-select-dropdown {
  cursor: pointer;
}

.react-select-dropdown .react-select-arrow {
  background-image: url(../img/down-arrow.png);
  background-position: right 0.4rem center;
  background-repeat: no-repeat;
  border: 0;
  width: 30px;
  height: 10px;
  position: absolute;
  right: 8px;
  top: 20px;
}

.order-item-filter-block .order-filter-option {
  width: 225px;
  float: right;
  font-size: 14px;
}

.order-filter-option span.filter-text-lbl {
  display: block;
  float: left;
  line-height: 39px;
  text-transform: uppercase;
  margin: 0rem 0.64rem;
  font-weight: bold;
}

.orders-item-filter-dropdown {
  float: left;
}

.orders-item-filter-dropdown select.form-control {
  width: 4rem;
  height: inherit;
}

.custom-select-control.orders-item-filter-dropdown {
  width: 4rem;
}

.orders-item-filter-dropdown > div {
  border: 0px;
  border-radius: 0px;
  background: #d7d4d8;
  width: 70px;
}

.orders-item-filter-dropdown > div > div:last-child {
  background: #ffffff;
}

.signupform .form-group {
  position: relative;
}

span.error.hide {
  display: none;
}

span.error.show {
  position: absolute;
  top: 100%;
  z-index: 5;
  width: 100%;
  display: block;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: 0rem;
}

span.error:before {
  content: '';
  border-bottom: 5px solid rgba(220, 53, 69, 0.8);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: -5px;
  width: 0;
}

/* Promotion Page */

.promotions-container .address.promo {
  padding: 1.5rem 2.5rem;
}

.redeemMain {
  background: #a9001c;
  text-align: center;
  padding: 25px 0px;
  color: #ffffff;
}

.redeemMain h2 {
  line-height: 24px;
  font-size: 1.5rem;
}

.redeemMain input {
  height: 50px;
  font-size: 1rem;
}

.redeemMain .btn-group {
  width: 60%;
}

.redeemMain .btn-group .btn {
  height: 50px;
  width: 40%;
  font-size: 1rem;
  border-radius: 5px !important;
}

.redeemMain p {
  font-size: 1.2rem;
  margin-bottom: 0px;
}

.promo .col-sm-6 {
  padding: 0px 5px;
}

.promotionBrandLogo {
  padding: 20px;
  background: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.promotionBrandLogo:after {
  content: '';
  width: 0;
  height: 0;
  border-top: 76.334px solid #f6f6f6;
  border-left: 36px solid #fff;
  border-bottom: 76.334px solid #f6f6f6;
  position: absolute;
  top: 0px;
  right: -35px;
  z-index: 2;
}

.promotionBrandLogo:before {
  content: '';
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  background: #f6f6f6;
  top: 64px;
  right: -42px;
  z-index: 3;
}

.promoContent {
  background: #f6f6f6;
  text-align: center;
  padding: 17.5px 5px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.promoBtn button {
  width: calc(48% - 5px);
  margin-right: 5px;
  font-size: 0.9rem;
  font-family: 'Aller Bold';
  padding: 0.375rem 0.75rem;
}

.promoBtn button.btn-default {
  border: 1px solid #ddd;
}

.promoBtn button.btn-primary {
  margin-right: 0px;
  background: #a9001c !important;
  color: #ffffff !important;
}

.promoWrapper {
  background: #fff;
  padding: 2px;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 2px #ddd;
  margin-bottom: 10px;
}

.promoContent .txt1 {
  font-size: 0.8rem;
  font-family: 'Aller Bold';
  margin: 0px;
}

.promoContent .txt2 {
  font-size: 1.5rem;
  font-family: 'Aller Bold';
  margin: 0px;
}

.promoContent .txt3 {
  font-size: 0.65rem;
  font-family: 'Aller Bold';
  margin: 0px;
  color: #c3c3c3;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}

.promoContent .txt4 {
  font-size: 0.7rem;
  font-family: 'Aller Bold';
  margin: 0px;
  margin-bottom: 2px;
}

.promoContent .div1 {
  line-height: 24px;
}

.promotion-info-modal {
  color: #ffffff;
}

.promotion-info-modal .promoWrapper {
  background: none;
  padding: 2rem 0rem 0rem 0rem;
  border-radius: 0px;
  box-shadow: none;
  margin-bottom: 10px;
}

.promotion-info-modal .promoContent {
  color: #030303;
  border-radius: 0px;
  padding: 18px 5px;
}

.promo-info-banner {
  margin-top: 0.5rem;
}

.promo-info-banner img {
  width: 100%;
}

.promotion-info-modal .redemPopbtn .btn {
  width: 100%;
  padding: 0.675rem 1.75rem;
}

.promotion-info-modal .redemPopTxt {
  padding: 2rem 1rem 0rem 1rem;
}

.promotions-container.myaccount-orders-block {
  padding: 0rem 0rem;
}

.promotion-info-modal .promotionBrandLogo img {
  max-width: 80%;
}

/*Account Details Page */

.mainacc_menusec {
  margin: 0 0 40px;
}

.mainacc_menulist {
  padding: 0;
  list-style: none;
  margin: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 0 0 20px;
}

.mainacc_menulist > li {
  display: table-cell;
  text-align: center;
  width: 50%;
  vertical-align: middle;
  border-left: 2px solid transparent;
}

.mainacc_menulist > li:first-child {
  border-left: 0;
}

.mainacc_menulist > li > a {
  background: #b69a6b;
  color: #fff;
  font-size: 16px;
  font-family: 'Berthold Akzidenz-Grotesk', sans-serif;
  text-transform: uppercase;
  padding: 17.5px 15px;
  display: block;
  position: relative;
}

.mainacc_menulist > li.active > a,
.mainacc_menulist > li > a:hover {
  border: 0;
  background: #0b0405;
  color: #fff;
}

.mainacc_menulist > li > a:after {
  content: '';
  border: 18px solid #0b0405;
  border-color: #0b0405 transparent transparent transparent;
  position: absolute;
  left: 50%;
  bottom: -36px;
  margin-left: -18px;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 400ms;
  -o-transition: all 400ms;
  transition: all 400ms;
}

.mainacc_menulist > li.active > a:after {
  opacity: 1;
}

.mbacc_mslidersec {
  display: none;
}

.mainacc_moblink {
  display: none;
}

.myacc_hea {
  position: relative;
  margin: 0 0 14px;
}

.myacc_hea::after,
.myacc_pg::after {
  display: block;
  content: '';
  clear: both;
}

.myacc_hea h3 {
  font-size: 27px;
  font-family: 'Berthold Akzidenz-Grotesk';
  margin-bottom: 5px;
}

.myacc_hea p {
  margin-bottom: 25px;
  color: #5b5b5b;
}

.myacc_hea_inner {
  margin-top: 8px;
}

.box_in {
  background: #fff;
}

.account_sec {
  display: -ms-flexbox;
  display: flex;
}

.accmenu_sec {
  background: #d6dde3;
  width: 260px;
  display: inline-block;
  position: relative;
}

.accprofile {
  padding: 26px 10px 16px;
}

.accprofile_img {
  width: 140px;
  height: 140px;
  border: 2px solid #fff;
  line-height: 134px;
  border-radius: 50%;
  margin: 0 auto 11px;
  overflow: hidden;
}

.accprofile_img img {
  vertical-align: middle;
}

.accprofile_info p {
  font-size: 16px;
  color: #0b0405;
  font-weight: normal;
  margin: 0;
}

.accpoints_sec {
  background: #e7e8ea;
  background: -webkit-linear-gradient(
    top,
    #e7e8ea 0%,
    #dde1e6 39%,
    #d5dce2 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#e7e8ea),
    color-stop(39%, #dde1e6),
    to(#d5dce2)
  );
  background: -o-linear-gradient(top, #e7e8ea 0%, #dde1e6 39%, #d5dce2 100%);
  background: linear-gradient(to bottom, #e7e8ea 0%, #dde1e6 39%, #d5dce2 100%);
  padding: 15px 7px 0px;
}

.accpoints_bx {
  /*background: #fffdfa url(../images/pattern.png) repeat;*/
  border-radius: 4px;
  color: #0b0405;
  position: relative;
}

.accpoint_tt {
  background: #ffffff;
  padding: 10px 24px 7px;
  position: relative;
  border-radius: 4px 4px 0 0;
}

.accpoint_tt:before {
  content: '';
  background: #f0efed;
  height: 6px;
  margin: auto 0;
  position: absolute;
  left: 24px;
  right: 18px;
  top: 0;
  bottom: 0;
}

.accpoint_tt img {
  max-width: 65px;
}

.accpoint_tt span {
  background: #fff;
  padding: 0 4px;
  position: relative;
  display: inline-block;
}

.accpoints_bxin {
  padding: 80px 13px 125px;
}

.accearn_points {
  background: #ecdfcc;
  padding: 15px 21px;
  border: 1px solid #ffffff;
}

.accearn_pointtop {
  position: relative;
  padding-left: 52px;
}

.accearn_pointtop i {
  position: absolute;
  left: 0;
  top: 7px;
}

.accearn_pointtop h4 {
  font-size: 12px;
  font-family: 'allerbold', sans-serif;
  margin: 0;
}

.accearn_pointtop span {
  font-size: 45px;
  font-family: 'Berthold Akzidenz-Grotesk', sans-serif;
  display: inline-block;
  line-height: 1;
  font-weight: normal;
}

.accearn_pointtop sub {
  font-size: 60%;
  bottom: 0;
}

.accearn_points p {
  font-size: 13px;
  margin: 0;
}

.accpoint_ec {
  position: absolute;
  right: 13px;
  bottom: 8px;
}

.accsec_right {
  width: calc(100% - 265px);
  width: -webkit-calc(100% - 265px);
  display: inline-block;
  padding: 55px 67px;
}

.accsec_right h1 {
  font-size: 1.5rem;
  color: #040507;
  margin-bottom: 9px;
  position: relative;
}

.accsec_right p {
  color: #000000;
  font-size: 16px;
}

.accsec_right .ttsplitter_md {
  max-width: 565px;
  margin: 30px auto 30px;
}

.acc-inform .row {
  margin-right: -8px;
  margin-left: -8px;
}

.acc-inform [class^='col-'] {
  padding-right: 8px;
  padding-left: 8px;
}

.acc-inform .focus-out {
  margin-bottom: 11px;
}

.acc-inform .form_grouptt {
  margin-top: 16px;
}

.other-add .ttsplitter {
  max-width: 459px;
}

.other-add-body {
  height: 175px;
  border: 1px solid #f1efef;
  border-radius: 7px;
  padding: 16px 14px 0px 14px;
}

.other-add-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
  width: 100%;
  padding-left: 0;
  margin-bottom: 0;
}

.other-add-row li {
  width: 32.1875%;
  margin-right: 1.6666666666666666%;
  position: relative;
  margin-bottom: 10px;
}

.other-add-row li:nth-of-type(3n + 3) {
  margin-right: 0;
}

.other-add-row li a {
  background: #f9f9f9;
  padding: 15px 15px;
  display: block;
  border: 1px solid #f1efef;
  color: #252525;
  font-size: 13px;
  border-radius: 7px;
  cursor: default;
}

.other-add-row span {
  position: absolute;
  top: 6px;
  right: 8px;
  background: url(../img/spirite_whole.png) no-repeat;
  width: 10px;
  height: 10px;
  background-position: -35px -315px;
  cursor: pointer;
  -webkit-transition: all 400ms;
  -o-transition: all 400ms;
  transition: all 400ms;
}

.other-add-row span:hover {
  opacity: 0.5;
}

.setting_menu_list {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.setting_menu_list > ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.setting_menu_list > ul li {
  position: relative;
  list-style: none;
}

.setting_menu_list > ul > li > a {
  padding: 12.5px 0 12.5px 37px;
  background: #e8eaec;
  color: #151515;
  display: block;
  text-transform: uppercase;
  border-bottom: 1px solid #fff;
  margin-bottom: 0;
}

.setting_menu_list > ul li a:hover {
  background: #333 !important;
  color: #fff !important;
}

.setting_menu_list > ul li:last-child a {
  border-bottom: 0;
}

.setting_menu_list > ul li:hover .myacc_dropmenu,
.mobile_mainacc_menulist > ul li:hover .myacc_dropmenu {
  opacity: 1;
  visibility: visible;
}

.setting_menu_list_icon {
  background: url(../img/spirite_whole.png) no-repeat;
  display: inline-block;
  margin-right: 16px;
  vertical-align: top;
}

.setting_menu_list_icon.password_icon {
  width: 14px;
  height: 17px;
  background-position: -295px -256px;
}

.setting_menu_list li a:hover .setting_menu_list_icon.password_icon {
  background-position: -326px -256px;
}

.setting_menu_list_icon.setting_icon {
  width: 14px;
  height: 14px;
  background-position: -365px -259px;
}

.setting_menu_list li a:hover .setting_menu_list_icon.setting_icon {
  background-position: -392px -259px;
}

.setting_menu_list_icon.logout_icon {
  width: 20px;
  height: 20px;
  background-position: -430px -255px;
}

.setting_menu_list li a:hover .setting_menu_list_icon.logout_icon {
  background-position: -462px -255px;
}

.myacc_dropmenu {
  position: absolute;
  left: 105%;
  top: -20px;
  list-style: none;
  padding-left: 0;
  background: rgba(0, 0, 0, 0.77);
  padding: 30px 35px;
  z-index: 1;
  width: 284px;
  border-radius: 7px;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
  opacity: 0;
  visibility: hidden;
}

.myacc_dropmenu::after {
  position: absolute;
  content: '';
  border-right: 14px solid #3b3b3b;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -7px;
}

.myacc_dropmenu::before {
  content: '';
  position: absolute;
  left: -13px;
  top: 0;
  background: #ccc;
  width: 20px;
  height: 100%;
  opacity: 0;
}

.myacc_dropmenu li a {
  color: #fff;
}

.myacc_dropmenu .tg-list {
  position: absolute;
  top: -2px;
  right: -4px;
  padding-top: 0;
  padding: 0;
}

.modal .order-details-item-block {
  margin: 2rem 0rem 0rem 0rem;
}

.react-select-control > div {
  border: 1px solid #e2e2e2;
  box-shadow: none;
  -webkit-box-shadow: none;
  background: #fff;
  font-size: 1rem;
  border-radius: 0;
  color: #615f5f;
  font-family: 'Open Sans', sans-serif;
}

.react-select-control > div:first-child {
  height: 50px;
}

.tgl {
  display: none;
}

.tgl,
.tgl *,
.tgl + .tgl-btn,
.tgl:after,
.tgl :after,
.tgl:before,
.tgl :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 45px;
  height: 22px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tgl-light + .tgl-btn {
  background: #f9f9f9;
  padding: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 2em;
}

.tgl-light:checked + .tgl-btn:after {
  background: #4dc93c;
}

.tgl:checked + .tgl-btn:after {
  left: 50%;
}

.tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #b4b4b4;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 22px;
  height: 22px;
}

.tgl + .tgl-btn:after {
  left: 1px;
}

.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
  position: absolute;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
}

.addressBlocks {
  background: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 10px 15px;
  position: relative;
  margin: 3px;
}

.addressBlocks p {
  margin: 0px;
  font-size: 0.8rem;
}

.addressBlocks span {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px;
  cursor: pointer;
}

.account_sec .btn_sec .btn,
.account_sec .btn_sec .btn:focus,
.account_sec .btn_sec .btn:hover {
  width: 50%;
  padding: 0.7rem;
  margin-top: 1rem;
  background: #a9001c !important;
  color: #ffffff !important;
}

.account_sec .react-select-dropdown {
  position: absolute;
  top: 40%;
  right: 0px;
}

/* Awards Sections */

.orders-list-page.customerList {
  padding: 0px;
}

.orders-list-tab.customerList-tab {
  padding: 0px;
}

.orders-list-page.customerList .nav-tabs {
  border-bottom: 3px solid #dddcdc;
  justify-content: space-around;
  background: #fff;
  padding: 10px 24px 0px;
}

.orders-list-tab.customerList-tab .form-group {
  margin-bottom: 0.4rem;
}

.orders-list-tab.customerList-tab .form-control {
  height: calc(2.4rem + 1px);
  border-radius: 2px;
}

.orderBtns {
  display: flex;
  justify-content: center;
}

.orderBtns .btn-primary {
  width: 50%;
  font-size: 0.8rem;
  margin-right: 5px;
}

.orderBtns .btn-default {
  background: #ccc;
  font-size: 0.8rem;
  margin-right: 5px;
}

.orderPanel {
  padding: 20px;
  float: left;
  width: 100%;
}

.orderList {
  background: #fff;
  border: 1px solid #ddd;
  margin-bottom: 15px;
}

.orderList-head,
.orderList-body,
.orderList-footer {
  padding: 10px 0px;
  border-bottom: 1px solid #ddd;
}

.orderList-head .btn-delivery {
  font-size: 0.7rem;
  background: #000;
  color: #fff;
  margin-right: 5px;
  padding: 6px 20px;
}

.orderList-head .calender {
  font-size: 0.8rem;
  margin-right: 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

.orderList-head .deliveryTime {
  font-size: 0.8rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

.viewReceipt {
  text-align: right;
}

.viewReceipt a {
  font-size: 0.8rem;
  font-family: 'Open Sans', sans-serif;
  color: #007bff;
  text-decoration: underline;
  font-weight: bold;
}

.viewReceipt span {
  font-size: 0.7rem;
  font-family: 'Open Sans', sans-serif;
  color: #0b1a29;
  margin: 0px 10px;
  vertical-align: text-top;
  line-height: 18px;
  font-weight: bold;
}

.myacunt-rewards-banner {
  background: #a9001c;
  float: left;
  width: 100%;
}

.myaccount-rewards-block {
  float: left;
  width: 100%;
  margin-top: 1.5rem;
}

.points {
  line-height: 24px;
  width: 320px;
  background: #000;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  margin: 2rem auto 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

.points p:nth-child(1) {
  font-size: 1rem;
  color: #0ebeff;
  margin: 0px;
}

.points p:nth-child(2) {
  font-size: 1.8rem;
  color: #fff;
  margin: 0px;
}

.points p:nth-child(3) {
  font-size: 0.8rem;
  color: #fff;
  margin: 0px;
}

.clickTxt {
  font-size: 0.7rem;
  line-height: 8px;
  font-family: 'Open Sans', sans-serif;
  margin-top: 0.5rem;
}

.orderList-body .deliveryAddress {
  font-size: 0.9rem;
  margin-top: 0;
  font-family: 'Open Sans', sans-serif;
  color: #666;
}

.table-borderless.orderListTable {
  width: 70%;
}

.table-borderless.orderListTable td {
  padding: 5px 10px 5px 0px;
  color: #666;
}

.orderList-footer .orderAmt {
  margin-top: 5px;
  font-family: 'Aller Bold';
}

.earnedTxt {
  margin-right: 5px;
  font-family: 'Aller Bold';
  text-transform: uppercase;
  font-size: 0.9rem;
}

.orderList-body h5 {
  font-family: 'Aller Bold';
}

.orderList-footer .orderAmt span {
  float: right;
}

.orderList-footer .btn-received {
  background: #d2233d;
  color: #fff;
  font-size: 0.9rem;
  padding: 6px 20px;
  border-radius: 0px;
  font-family: 'Open Sans', sans-serif;
}

.myaccount-orders-options .order-option-list li .btn {
  padding: 0rem 1rem;
  line-height: 4rem;
  text-align: left;
  text-indent: 1rem;
  border-radius: 0.8rem;
}

.myaccount-orders-options .order-option-list .order-icon {
  float: left;
  width: 50px;
  height: 50px;
  background-size: 88% !important;
  margin: 0.4rem auto;
}

.myaccount-orders-options .order-option-list li.self-collection .order-icon {
  background-size: 50% !important;
}

.cms-page-container {
  background: #ffffff;
  padding: 2rem;
  margin-bottom: 1rem;
  line-height: 1.5;
}

/*
|--------------------------------------------------------------------------
|  4.0 Footer
|  
|--------------------------------------------------------------------------
|
| 
|
*/
.feedback-form {
    padding-right: 6rem;
}
.feedbackform_submit .btn {
  padding: 0.7rem 0rem;
  height: 51px;
  font-size: 1.2rem;
  font-family: 'Aller Bold';
  margin-bottom: 2rem;
  text-align: right;
  color: #fff;
  text-decoration: underline;
}

footer .footer-quicklinks-container {
  background-color: #2e2e2e;
  padding: 3rem 0px 0px;
  color: #ffffff;
}

footer .container {
  padding-bottom: 0rem;
}

footer .brand-colm {
  margin-top: 6px;
}

footer .brand-logo {
  color: #ffffff;
  font-weight: 600;
  font-family: 'Luckiest Guy', cursive;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 2rem;
  width: 150px;
  line-height: 1.6rem;
  white-space: normal;
  margin-bottom: 10px;
}

footer ul {
  margin-left: 0;
  padding-left: 0;
  overflow: hidden;
}

footer .nav-header {
  color: #fff;
  padding: 1rem 0px;
  display: block;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 3px;
  font-family: 'GothamLight';
}

ul.footer-nav li,
ul.footer-social li {
  list-style: none;
  line-height: 2rem;
}

ul.footer-nav li p,
ul.footer-nav li a {
  color: #fff;
  letter-spacing: 2px;
  text-decoration: none;
  font-size: 14px;
}

.navbar-footer {
  border-top: 2px solid #1c1c1c; margin: 20px 0 0;
}

.navbar-footer ul.footer-nav li a {
  text-transform: uppercase;
  font-family: 'Aller Bold';
  color: #c5c5c5;
}

footer .social-colm h6 {
  font-size: 14px;
  margin-right: 10px;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
}

.footer-social li {
  float: left;
  margin-right: 10px;
}

.footer-social-container {
  background: #1c1c1c;
  text-align: center;
  padding: 15px;
}

.footer-social-container .social-colm {
  justify-content: center;
}

.footer-copyright-container {
  color: #000000;
  text-align: center;
  padding: 15px;
}

.footer-copyright-container p {
  margin: 0px;
}

/*  custom alert box start here */

.alert_header {
  background: #34353a;
  color: #fff;
  font-size: 17px;
  padding: 7px 14px;
  position: relative;
  text-align: left;
}

.alrt_overlay {
  background: #fff;
  bottom: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.custom_alert {
  bottom: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 450px;
  z-index: 9999;
  display: table;
  height: inherit !important;
}

.custom_alertin {
  display: table-cell;
  vertical-align: middle;
}

.custom_alertin .alert_height {
  background: #fff;
  box-shadow: 0 0 20px rgba(86, 84, 84, 0.65);
  -webkit-box-shadow: 0 0 20px rgba(86, 84, 84, 0.65);
}

.alert_body {
  text-align: center;
  padding: 20px;
}

.alt_btns a {
  margin: 10px 5px 0;
  padding: 0.375rem 1.75rem;
  letter-spacing: 1px;
}

/* .alt_btns a{background : #000 ; color: #fff} */

.alt_btns a {
  background: #000;
  color: #fff !important;
}

.alt_btns a:hover,
.alt_btns a:focus {
  background: #000 !important;
  color: #fff !important;
}

.alt_btns a.confirm {
  background: #d2233d;
}

.alt_btns a.reject:hover {
  background: #d2233d !important;
}

.alert-success {
  padding: 0.8rem;
  margin-bottom: 0.8rem;
}

.alert-success p {
  margin-bottom: 0px;
}

.popup-body .alert-success {
  margin-bottom: 0.8rem;
}

.myaccount-orders-options .order-option-tem .btn {
  position: relative;
}

.order-option-list .order-option-tem .btn.active,
.order-option-list .order-option-tem .btn:hover {
  background-color: #181818;
  color: #ffffff;
}

/* custom alert box css end here */

/* Error */

.error {
  color: #f00;
  font-weight: normal;
}

span.error {
  background: #ffbaba;
  color: #d8000c;
  padding: 5px 25px;
  font-size: 13px;
  position: relative;
  display: inline-block;
  font-weight: normal;
  z-index: 4;
}

span.error:before {
  content: '';
  border-bottom: 5px solid #ffbaba;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: -5px;
  width: 0;
}

/* Alert */

.alert {
  padding: 10px 15px;
  border-radius: 0;
  -webkit-border-radius: 0;
  text-align: left;
  display: none;
  -webkit-transition: all 400ms;
  -o-transition: all 400ms;
  transition: all 400ms;
}

.alert-error {
  padding: 1rem;
  border-radius: 0;
  background-color: #f2dede;
  border-color: #ebccd1;
  display: none;
  margin-bottom: 0.5rem;
}

.alert_fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 185px;
  z-index: 999;
  max-width: 1280px;
  margin: 0 auto;
}

.alert_success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert_danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert p,
.alert-error p {
  margin: 0px;
}

.custom-select-control {
  position: relative;
  width: 100%;
}

.custom-select-control:after {
  content: '';
  font: normal normal normal 22px/1 FontAwesome;
  color: #0ebeff;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 2.1rem;
  position: absolute;
  pointer-events: none;
  background-image: url('../img/down-arrow.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1rem;
}

/* IE11 hide native button (thanks Matt!) */

select::-ms-expand {
  display: none;
}

.custom-select-control select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */
  display: block;
  width: 100%;
  word-break: normal;
}

.btn-toggle {
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3.1rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #bdc1c8;
}

.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
  outline: none !important;
  box-shadow: none !important;
}

.btn-toggle:before,
.btn-toggle:after {
  line-height: 1.5rem;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
  font-family: 'Open Sans', sans-serif;
}

.btn-toggle:before {
  content: 'Off';
  left: -4rem;
}

.btn-toggle:after {
  content: 'On';
  right: -4rem;
  opacity: 0.5;
}

.btn-toggle > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}

.btn-toggle.active {
  transition: background-color 0.25s;
}

.btn-toggle.active > .handle {
  left: 1.6875rem;
  transition: left 0.25s;
}

.btn-toggle.active:before {
  opacity: 0.5;
}

.btn-toggle.active:after {
  opacity: 1;
}

.btn-toggle.btn-sm:before,
.btn-toggle.btn-sm:after {
  line-height: -0.5rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.4125rem;
  width: 2.325rem;
}

.btn-toggle.btn-sm:before {
  text-align: right;
}

.btn-toggle.btn-sm:after {
  text-align: left;
  opacity: 0;
}

.btn-toggle.btn-sm.active:before {
  opacity: 0;
}

.btn-toggle.btn-sm.active:after {
  opacity: 1;
}

.btn-toggle.btn-xs:before,
.btn-toggle.btn-xs:after {
  display: none;
}

.btn-toggle:before,
.btn-toggle:after {
  color: #6b7381;
}

.btn-toggle.active {
  background-color: #29b5a8;
}

.btn-toggle.btn-secondary {
  color: #6b7381;
  background: #bdc1c8;
}

.btn-toggle.btn-secondary:before {
  color: #6b7381;
}

.btn-toggle.btn-secondary:after {
  color: #ffffff;
}

.btn-toggle.btn-secondary.active {
  background-color: #4dc93c !important;
}

.map-location-link {
  position: relative;
  padding-left: 32px;
  color: #ffff;
}

.map-location-link::before {
  content: '';
  width: 24px;
  height: 24px;
  left: 0;
  top: -2px;
  position: absolute;
  background: #f9f9f9 url(../img/location-mp-icon.png) no-repeat center;
  z-index: 2;
}

.pmodifier_ddlist ul {
  padding: 0;
  margin: 7px 0;
  list-style: none;
}

.pmodifier_ddlist li {
  background: #a9001c;
  padding: 7px 35px 7px 17px;
  border: 1px solid #dcd8d8;
  border-radius: 20px;
  position: relative;
  font-weight: 700;
  color: #ffffff;
  margin: 0 0 4px;
  font-size: 0.9rem;
}

.pmodifier_close {
  background: #000000 url(../img/cross-symbol.PNG) center no-repeat;
  width: 48px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0 20px 20px 0;
  margin: auto 0;
}

.re_select .Select-control {
  border: 1px solid #e2e2e2;
  border-radius: 0;
  height: 47px;
  outline: 0;
}

ul.table_list.cart_sub_items.combo {
  padding: 0;
  padding-bottom: 15px;
}

.table_list {
  margin: 0;
  padding: 0 0 0 0px;
}

.table_list li {
  list-style: none;
  font-size: 13px;
  margin-top: 4px;
}
.table_list li.sub-item b{ display: block; margin-top: 10px; }
.table_list li.sub-item:first-child{ margin-top: 0; }
.table_list li.sub-item:first-child b{ margin-top: 0px; }

.cart_sub_items {
  padding-top: 5px;
  color: #444;
  font-weight: 400;
}

.main-wrapper {
  overflow: hidden;
}

.searchfilter-Selector__indicators {
  display: none !important;
}

.searchfilter-Selector__control {
  height: 54px;
}

/*MOBILE MY ACCOUNT DELIVERY*/

.mobile_accountdel_body {
  display: none;
  width: 100%;
}

.mobile_accountdel_row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.mobile_accountdel_col {
  width: 50%;
  position: relative;
}

.mobile_account_item {
  position: relative;
}

.mobile_account_item .mobile_account_item_loggedin > a > i {
  position: absolute;
  left: 15px;
  top: 50%;
  background: url(../img/user-icon.png) no-repeat center;
  width: 35px;
  height: 35px;
  line-height: 32px;
  display: inline-block;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.mobile_account_item .mobile_account_item_loggedin > a {
  padding: 15px 55px 15px 65px;
  background: #000000;
  color: #fff;
  display: block;
  font-family: 'Gotham Black', sans-serif;
  font-size: 18px;
  text-transform: uppercase;
}

.mobile_mainacc_menutrigger {
  background: url(../img/down-arrow-white.png) no-repeat center;
  background-size: 24px auto;
  float: right;
  width: 55px;
  height: 49px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.mobile_account_item_login {
  background: #000000;
}

.mobile_account_item_login > a {
  display: inline-block;
  padding: 15px 55px 15px 65px;
  background: #000000;
  color: #fff;
  font-family: 'Gotham Black', sans-serif;
  font-size: 18px;
  text-transform: uppercase;
}

.mobile_account_item_login > a > i {
  position: absolute;
  left: 15px;
  top: 50%;
  background: url(../img/user-icon.png) no-repeat center;
  width: 35px;
  height: 35px;
  line-height: 32px;
  display: inline-block;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.mobile_account_item_login > a.signup_btn {
  padding-left: 0;
}

.mobile_mainacc_menulist {
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  z-index: 1;
  display: none;
}

.mobile_mainacc_menulist > ul {
  margin: 0;
  padding: 0;
}

.mobile_mainacc_menulist li a {
  display: block;
  padding: 12px 15px 12px 65px;
  background: #000000;
  font-family: 'Aller', sans-serif;
  font-size: 18px;
  position: relative;
  color: #fff;
  text-transform: uppercase;
}

.mobile_mainacc_menulist li a:hover {
  color: #ffffff;
}

.mobile_mainacc_menulist .setting_menu_list_icon {
  position: absolute;
  left: 23px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.mobile_mainacc_menulist .setting_menu_list_icon.password_icon {
  background-position: -326px -256px;
}

.mobile_mainacc_menulist .setting_menu_list_icon.setting_icon {
  background-position: -392px -259px;
}

.mobile_mainacc_menulist .setting_menu_list_icon.logout_icon {
  background-position: -462px -255px;
}

.mobile_account_delivery_item {
  list-style: none;
  padding-left: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  position: absolute;
}

.myaccount_separate {
  color: #fff;
  display: inline-block;
  padding: 0 10px;
  font-size: 18px;
}

.mobile_account_item_login a {
  padding-right: 0;
}

.mobile_account_delivery_item a {
  padding: 15px 15px 15px 80px;
  background: #a9001c;
  display: block;
  font-size: 18px;
  color: #fff;
  font-family: 'Aller', sans-serif;
  text-transform: uppercase;
  position: relative;
}

.mobile_account_delivery_item a {
  border-bottom: 0.5px solid #ffffff;
}

.mobile_account_delivery_item a:hover {
  color: #fff;
}

.mobile_account_delivery_item li a i {
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.mobile_account_delivery_item li.delivery_mob a i {
  background: url(../img/dinein-icon.png) no-repeat center;
  background-size: auto 35px;
  width: 50px;
  height: 35px;
}

.mobile_account_delivery_item li.takeaway_mob a i {
  background: url(../img/take-away-white.png) no-repeat center;
  background-size: auto 28px;
  width: 50px;
  height: 35px;
}

.mobile_account_menu {
  background: #191919;
  display: none;
}

.mobile_account_menu h3 {
  color: #fff;
  text-align: center;
  font-family: 'Aller', sans-serif;
  text-transform: uppercase;
  border-bottom: 1px solid #fff;
  padding: 10px 10px;
}

.mobile_account_menu_click {
  padding: 15px 55px 19px 80px;
  background: #a9001c;
  display: block;
  font-size: 15px;
  color: #fff;
  font-family: 'Gotham Black', sans-serif;
  text-transform: uppercase;
  position: relative;
}

.mobile_account_menu_click:hover {
  color: #ffffff;
  text-decoration: none;
}

.mobile_account_menu_click i.arrow_myaccount {
  background: url(../img/down-arrow-white.png) no-repeat center;
  background-size: 24px auto;
  float: right;
  width: 55px;
  height: 49px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.mobile_account_menu_click i.arrow_myaccount.active {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
  top: 35%;
}

.mobile_account_menu_click i.mouse-myaccount {
  background: url(../img/mouse.png) no-repeat center;
  /*background-size: auto 35px;*/
  float: right;
  width: 50px;
  height: 35px;
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.mobile_account_menu_click i.scotter-myaccount {
  background: url(../img/scootter-icon.png) no-repeat center;
  background-size: auto 35px;
  float: right;
  width: 50px;
  height: 35px;
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.mobile_account_menu_click i.takeaway-myaccount {
  background: url(../img/take-away-white.png) no-repeat center;
  background-size: auto 35px;
  float: right;
  width: 50px;
  height: 35px;
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.mobile_account_item a:hover,
.mobile_account_delivery_item a:hover {
  text-decoration: none;
}

.mobile_setting_menu_list {
  position: relative;
}

.mobile_mainacc_menulist .myacc_dropmenu {
  left: 70%;
  top: -12px;
  list-style: none;
  padding-left: 0;
  background: rgba(0, 0, 0, 0.77);
  padding: 25px 9px;
  z-index: 1;
  width: 180px;
  border-radius: 7px;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
  opacity: 0;
  visibility: hidden;
}

.mobile_mainacc_menulist .myacc_dropmenu .tg-list {
  top: 25px;
  right: 10px;
}

.mobile_mainacc_menulist .myacc_dropmenu > li > a {
  background: none;
  padding: 0px;
  font-size: 0.8rem;
}

.mobile_mainacc_menulist .myacc_dropmenu::after {
  border-right: 14px solid #efebeb;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  left: -17px;
}

.mobile-category-dropdown {
  position: fixed;
  top: 118px;
  width: 100%;
  left: 0;
  z-index: 999;
  display: none; height: 0;
}

.mobile-category-dropdown .dropdown {
  background: #f5f5f5;
  box-shadow: -32px -64px 30px 41px rgb(16, 8, 8);
}

.mobile-category-dropdown .dropdown .dropdown-toggle {
  width: calc(100% - 130px);
  background: #f5f5f5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.575rem 1rem;
  text-align: left;
}

.mobile-category-dropdown .dropdown .dropdown-toggle:focus {
  box-shadow: none;
}

.mobile-category-dropdown .dropdown .dropdown-toggle::after {
  content: 'View More';
  position: absolute;
  right: 10px;
  top: 5px;
  background: url(../img/list-icon.png) no-repeat center;
  width: 130px;
  height: 30px;
  border: 0px;
  background-size: 30px;
  background-position: right center;
  padding-top: 2px;
}

.mobile-category-dropdown .dropdown.show .dropdown-toggle::after {
  background-image: url(../img/close.png);
  background-size: 18px auto;
}

.scrollable-menu {
  height: auto;
  max-height: 300px;
  overflow-x: hidden;
  transform: translate3d(0px, 40px, 0px) !important;
  width: 100%;
  border: 0px;
  border-radius: 0px;
  background: #000000;
}

.scrollable-menu .dropdown-item {
  padding: 1rem;
  text-transform: uppercase;
  color: #ffffff;
}

.scrollable-menu .dropdown-item.active,
.scrollable-menu .dropdown-item:active,
.scrollable-menu .dropdown-item:focus,
.scrollable-menu .dropdown-item:hover {
  background: #f5f5f5;
  color: #000000;
}

.dropdown-menu {
  font-size: 0.8rem;
}

.card a {
  text-decoration: none;
}

.ecoupons-nav-item a {
  text-transform: inherit !important;
  font-size: 0.9rem !important;
}

.dont-msg {
  justify-content: flex-start;
  padding-left: 35px;
  margin-top: -10px;
}
.video-popup-header {
  padding-bottom: 25px;
}
