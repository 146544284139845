html [lang='en'],
body.bg-primary {
  height: auto !important;
  overflow: visible !important;
}
a {
  transition: all 400ms;
}
/* .quandoo-s{ left: -136px !important; right: auto !important; }  Must Delete this line */
.btn_normal {
  height: 51px;
  transition: all 400ms;
  font-size: 18px;
  padding: 12px 25px;
}
.btn_pink {
  background: #a9001c;
  color: #fff;
}
.btn_pink:hover {
  background: #000;
  color: #fff;
}
.btn_black {
  background: #000;
  color: #fff;
}
.btn_black:hover {
  background: #a9001c;
  color: #fff;
}
/* Header */
header.main-header {
  box-shadow: 0px 1px 7px #e2e2e2;
  border-bottom-color: #e2e2e2;
}
.top-info-menu {
  background: rgb(24, 24, 24);
}
.top-info-menu .container {
  position: relative;
}
.info-text {
  color: #fff;
  padding: 14px 155px;
  text-align: center;
  font-family: Aller Bold;
  text-transform: uppercase;
  font-size: 14px;
  min-height: 42px;
}
.info-text p {
  margin: 0;
  font-size: inherit;
  line-height: 1;
}
.top-header-account {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px 0;
}
.inner-header-account:not(:first-child) {
  border-left-color: #e7e8e8;
}
.inner-header-account a {
  color: #e7e8e8;
  text-decoration: none;
}
.inner-header-account a:hover {
  color: #a9001c;
}
.inner-header-account a img {
  margin: -3px 5px 0 0;
}
.mobilenav_wrapper {
  display: none;
}
#mainNav {
  height: auto;
}
.navbar-brand {
  padding-top: 7px;
  padding-bottom: 7px;
}
.main-menu-block {
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem 0rem 1rem;
}
#mainNav .navbar {
  padding: 0 70px 0 40px;
}
.mainmenu-right {
  padding: 0;
}
#mainNav .navbar-nav > li {
  line-height: 2.5rem;
}
#mainNav .navbar-nav > li > a {
  padding: 0 18px;
  font-size: 1rem;
}
.hordernow_btn a {
  background: #a9001c;
  display: inline-block;
  padding: 12.5px 12px 9.5px 10px;
  color: #fff;
  text-decoration: none;
  font-family: Raiders;
  font-size: 17px;
}
.hordernow_btn a:hover {
  background: #000;
}
.hordernow_btn a img {
  margin-top: -2px;
  margin-right: 6px;
}
.cart-box .cart-icon-bag .number {
  font-size: 12px;
  font-family: Aller;
  width: 23px;
  height: 23px;
  top: -22px;
  left: 27px;
}
/* Header end */

/* Menu */
.delivery-section section.inner-page-header {
  background: #fff;
  padding: 20px 0 55px;
}
.delivery-section .page-header-block {
  padding: 0;
}
.delivery-section .page-header-block h1.font-gothamlight {
  overflow: hidden;
  margin-bottom: 0;
  font-size: 51px;
  font-family: 'Raiders';
}
.delivery-section .page-header-block h1.font-gothamlight:before {
  margin-left: -51%;
  width: 50%;
  height: 2px;
  background: #181818;
}
.delivery-section .page-header-block h1.font-gothamlight:after {
  width: 50%;
  margin-left: 1%;
  height: 2px;
  background: #181818;
}
.page-header-block .breadcrumb {
  justify-content: center;
}
.page-header-block .breadcrumb li {
  color: #0a0b10;
  font-size: 17px;
  font-family: 'GothamLight';
}
.page-header-block .breadcrumb-item + .breadcrumb-item::before {
  color: #0a0b10;
}
.page-header-block .breadcrumb li a {
  color: #0a0b10;
}
.page-header-block .breadcrumb li.breadcrumb-item.active {
  color: #0a0b10;
}
.plist_sec {
  display: flex;
  flex-wrap: wrap;
}
.plist_tab {
  width: 295px;
  height: calc(100vh - 124px);
  height: -webkit-calc(100vh - 124px);
  position: relative;
}
.plist_tabmenu_out {
  position: absolute;
  top: 0;
  overflow-y: auto;
  width: inherit;
  height: inherit;
}
.plist_tab.fixed .plist_tabmenu_out {
  position: fixed;
  top: 135px;
}
.plist_tabmenu {
  padding: 0;
  margin: 0;
  list-style: none;
}
.plist_tabmenu > li {
  margin-bottom: 5px;
}
.plist_tabmenu > li > a {
  background: rgb(245, 245, 245);
  border: 1px solid rgb(236, 236, 236);
  display: block;
  padding: 29px 25px;
  font-family: 'GothamLight';
  font-size: 19px;
  color: #0a0b10;
  position: relative;
  transition: all 400ms;
  text-decoration: none;
}
.plist_tabmenu > li > a:after {
  content: '';
  background: #a9001c;
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  width: 7px;
  opacity: 0;
  transition: all 400ms;
}
.plist_tabmenu > li > a.active,
.plist_tabmenu > li:hover > a {
  background: rgb(255, 255, 255);
}
.plist_tabmenu > li > a.active:after,
.plist_tabmenu > li:hover > a:after {
  opacity: 1;
  height: 100%;
}
.plist_content {
  width: calc(100% - 320px);
  width: -webkit-calc(100% - 320px);
  margin-left: 25px;
}
.subcategory_tt {
  margin: 20px 0 15px;
}
.subcategory_tt h3 {
  /*font-family: 'GothamLight';*/
  font-weight: bold;
  font-size: 20px;
  color: #0a0b10;
  margin: 0;
}
.plist_grid .product-item-colm {
  padding-right: 10px;
  padding-left: 10px;
  transition: all 400ms;
}
.plist_grid > .row {
  margin-right: -10px;
  margin-left: -10px;
}
.delivery-section .spct-menu-list .product-item-colm .card {
  background: #fff;
  box-shadow: none;
}
.plist_grid .card-body {
  padding: 10px 10px 15px;
  display: flex;
  flex-flow: column;
}
.plist_grid .product-tag {
  position: relative;
  margin-bottom: 15px;
}
.plist_grid .product-tag:before {
  content: '';
  border-bottom: 1px dotted #888;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 0;
  right: 0;
}
.plist_grid .card-title {
  margin-bottom: 10px;
}
.plist_grid .product-tag span {
  background: rgb(27, 163, 91);
  padding: 3.5px 11px;
  color: #fff;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 13px;
  position: relative;
  display: inline-block;
  margin: 0 1.5px;
}
.delivery-section .spct-menu-list .product-item-colm .card-body .menu-set-tag {
  display: flex;
  justify-content: center;
}
.plist_grid .menu-set-tag {
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  margin: 0 0 15px;
}
.delivery-section .spct-menu-list .product-item-colm .card-body .price-tag {
  margin: 0 0 7px;
}
.delivery-section
  .spct-menu-list
  .product-item-colm
  .card-body
  .btn.add-to-btn-cart {
  background: #a9001c;
  color: #fff;
  width: auto;
  min-width: 167px;
  max-width: 100%;
  margin: 0 auto;
  padding: 9px 15px;
  transition: all 400ms;
  text-decoration: none;
}
.delivery-section
  .spct-menu-list
  .product-item-colm
  .card-body
  .btn.add-to-btn-cart:hover {
  background: #fff !important;
  color: #a9001c !important;
}
.plist_grid .product-item-colm:hover .card-body {
  background: #a9001c;
}
.plist_grid .product-item-colm:hover .card-title {
  color: #fff;
}
.plist_grid .product-item-colm:hover .menu-set-tag {
  color: #fff;
}
.plist_grid .product-item-colm:hover .price-tag {
  color: #fff;
}
.delivery-section
  .spct-menu-list
  .plist_grid
  .product-item-colm:hover
  .card-body
  .btn.add-to-btn-cart {
  background: #fff !important;
  color: #a9001c !important;
}

/* Popup */
.popup_left {
  min-height: 365px;
  display: flex;
  align-items: center;
}
.popup_left .delivery-address-block {
  width: 100%;
}
.popup_right {
  background: #fff;
  padding: 10px 65px;
  display: flex;
  align-items: center;
}
.delivery-datetime-modal .popup_right {
  padding: 50px 65px;
}
.popup_right .innervmid_in {
  width: 100%;
}
.popup_right .syd_merge {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px;
}
.popup_right .syd_date {
  width: 50%;
  padding: 0 4px;
}
.popup_right .syd_merge .react-datepicker-wrapper,
.popup_right .syd_date .react-datepicker-wrapper {
  display: block;
}
.popup_right .syd_time {
  width: 50%;
  padding: 0 4px;
}
.popup_right .btn_sec {
  width: 100%;
}
.popup_right .btn_sec .row {
  margin-left: -4px;
  margin-right: -4px;
}
.popup_right .btn_sec .row [class*='col-'] {
  padding: 0 4px;
}
/* Order type popup */
.modal .close {
  background-color: #181818;
}
.delivery-datetime-modal .modal-body .ordertype_address {
  padding: 50px 0 70px;
}
.ordertype_address h1 {
  font-family: 'GothamLight';
  font-size: 36px;
  font-weight: normal;
  margin: 0 0 5px;
}
.ordertype_address .label-text {
  font-family: 'Raiders';
  font-size: 19.5px;
  margin: 0 0 10px;
  letter-spacing: 0.6px;
}
.ordertype_list li {
  max-width: 240px;
  margin: 0 5px 10px;
}
.ordertype_list li .btn {
  background-color: #ede4e8;
  padding: 28px 10px;
  font-size: 24px;
}
.ordertype_list li .order-icon {
  margin: 0 auto;
}
.ordertype_list li.delivery .order-icon {
  background-image: url(../img/ico-delivery.png);
}
.ordertype_list li.dine-in .order-icon {
  background-image: url(../img/ico-dinein.png);
}
.ordertype_list li.self-collection .order-icon {
  background-image: url(../img/ico-self.png);
}
.ordertype_list li.delivery .btn.active .order-icon,
.ordertype_list li.delivery .btn:hover .order-icon {
  background-image: url(../img/ico-delivery-white.png);
}
.ordertype_list li.dine-in .btn.active .order-icon,
.ordertype_list li.dine-in .btn:hover .order-icon {
  background-image: url(../img/ico-dinein-white.png);
}
.ordertype_list li.self-collection .btn.active .order-icon,
.ordertype_list li.self-collection .btn:hover .order-icon {
  background-image: url(../img/ico-self-white.png);
}
/* Sorry Popup */
.sorry_popup {
  padding: 40px 100px;
  text-align: center;
  color: #fff;
}
.sorry_popup h1 {
  font-family: 'GothamLight';
  font-size: 36px;
  font-weight: normal;
  margin: 0 0 5px;
  color: #fff;
}

/* Product Detail */
.product_detpopup .modal-dialog {
  max-width: 950px;
}
.product_detpopup .close {
  background-color: #181818;
  top: 0;
  right: 0;
  border-radius: 0;
}
.product_detsec {
  padding: 30px 10px 0;
}
.product_slidersec .product-more-slider.slick-initialized .product-slide {
  border: 0;
}
.product_slidersec .product-slide {
  text-align: center;
  height: 420px;
}
.product_slidersec .product-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.product_detsec .product_info {
  padding: 35px 15px 0;
}
.pdet_ttrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 5px;
}
.product-details .card-header h5.pdet_tt {
  font-weight: normal;
  font-family: 'GothamLight';
  font-size: 34px;
  margin: 0;
  padding-right: 15px;
}
.pdet_off {
  background: #060203;
  color: #fbea32;
  padding: 6.5px 5px;
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  text-align: center;
  width: 91px;
  letter-spacing: -1px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -5px;
}
.pdet_rating {
  color: rgb(149, 111, 75);
  min-width: 90px;
  text-align: right;
}
.product_detsec .product-tag {
  margin-bottom: 25px;
}
.product_detsec .product-tag span {
  background: rgb(27, 163, 91);
  padding: 4px 11px;
  color: #fff;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 13px;
  position: relative;
  display: inline-block;
  margin: 0 1.5px;
}
.pdet_des {
  font-weight: 300;
  margin: 0;
}
.product_detsec .product-info-colm .card-body {
  padding: 0;
  background: none;
}
.product_detsec .choosen-product-options-colm {
  padding: 0;
}
.product_detsec .product-choosen-item {
  margin: 0 0 40px;
}
.product_detsec .accordion_head {
  background: rgb(249, 249, 249);
  padding: 13px 50px 13px 25px;
  position: relative;
  margin: 0 0 10px;
  cursor: pointer;
}
.product_detsec .accordion_head h6 {
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0;
  font-weight: 700;
  color: #383838;
}
.product_detsec .accordion_head .plusminus {
  position: absolute;
  right: 0;
  top: 0;
  width: 45px;
  height: 45px;
  line-height: 43px;
  text-align: center;
  color: rgb(168, 168, 168);
  font-size: 34px;
  font-family: 'GothamLight';
}
.product_detsec .accordion_body {
  padding: 0 0 0 25px;
}
.product_detsec .max-min-bar {
  font-size: 15px;
  margin: 0 0 8px;
}
.product_detsec .chosen_adn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 9px;
}
.product_detsec .chosen_adn_left {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  padding-right: 8px;
}
.product_detsec .css-1uccc91-singleValue {
  max-width: calc(100% - 38px);
}
.product_detsec .qty_bx {
  width: 105px;
  height: 38px;
  position: relative;
  padding: 0 34px;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
}
.product_detsec .qty_bx input {
  background: rgb(6, 2, 3);
  border-radius: 5px;
  color: #fff;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 13px;
  text-align: center;
  margin: 0;
  padding: 2px;
  font-size: 19px;
  font-weight: 700;
  outline: 0;
}
.product_detsec .qty_bx span {
  border: 1px solid #a9001c;
  color: #a9001c;
  position: absolute;
  width: 32px;
  height: 38px;
  line-height: 36px;
  display: block;
  text-align: center;
  top: 0;
  cursor: pointer;
  font-size: 32px;
  border-radius: 5px;
  -webkit-transition: all 400ms;
  -o-transition: all 400ms;
  transition: all 400ms;
  font-family: 'GothamLight';
}
.product_detsec .qty_bx span:hover {
  background: #a9001c;
  color: #fff;
}
.product_detsec .qty_bx .qty_minus {
  left: 0;
}
.product_detsec .qty_bx .qty_plus {
  right: 0;
}
.product_detsec .combo-input-label {
  background: #060203;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
}
.re_select .css-1uccc91-singleValue {
  line-height: normal;
}
.prochoosen_row {
  display: flex;
  align-items: center;
  margin: 0 0 10px;
}
.prochoosen_row .product_chosen_hea,
.prochoosen_row .product-size-options-block {
  width: 20%;
  font-family: 'GothamLight';
}
.prochoosen_row .product_chosen_hea h6 {
  font-family: 'GothamLight';
  font-weight: normal;
  margin: 0;
}
.prochoosen_row .re_select {
  width: 80%;
}
.product_detsec .react-select-control > div:first-child {
  display: flex;
}
.product_detsec textarea.form-control {
  height: 150px;
  resize: none;
  padding: 11px 15px;
}
.product_detpopup .modal-footer {
  padding: 0;
}
.product_detpopup .prd-total-price {
  font-size: 34px;
  font-family: 'GothamLight';
  color: #0a0b10;
  font-weight: normal;
  color: #0a0b10;
  margin: 0 0 12px;
}
.product_detpopup .prd-total-price sup {
  left: 10px;
  font-size: 20px;
  top: -11px;
}
.product_detpopup .prd-chosen-sub-col {
  max-width: none;
  background: none;
  border: 0;
  display: block;
  padding: 0;
}
.product_detpopup .prd-chosen-sub-item-left {
  width: auto;
}
.product_detpopup .prd-chosen-sub-item-right {
  width: auto;
}
.product_detpopup .prd-chosen-sub-item-right button {
  max-width: none;
}
.product_detpopup .prd-chosen-sub-item-left .qty-bx {
  width: 142px;
  margin: 0 0 20px;
}
.product_detpopup .qty-bx {
  padding: 0 45px;
  height: 53px;
}
.product_detpopup .qty-bx span {
  color: #a9001c;
  width: 43px;
  height: 53px;
  line-height: 51px;
  font-size: 32px;
  font-family: 'GothamLight';
}
.product_detpopup .prd-chosen-sub-item-left .qty-bx .qty-minus {
  background: none;
  border: 1px solid #a9001c;
}
.product_detpopup .prd-chosen-sub-item-left .qty-bx .qty-plus {
  background: none;
  border: 1px solid #a9001c;
}
.product_detpopup .prd-chosen-sub-item-left .qty-bx input {
  background: #060203;
  border-radius: 5px;
  color: #fff;
}
.product_detpopup .prd-chosen-sub-item-right button {
  font-family: 'Raiders';
  padding: 15px 15px;
  line-height: 1;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 1.5px;
}

.prochoosen_row .react-select-control > div {
  position: relative;
}
.prochoosen_row .css-g1d714-ValueContainer {
  padding-right: 40px;
}
.prochoosen_row .css-1hb7zxy-IndicatorsContainer {
  position: absolute;
  right: 10px;
  top: 5px;
}
.order-option-list li {
  border: 2px solid #b64a77;
}
.account_sec {
  margin-top: 30px;
}
.checkout-payment-actions
  .custom-control-input:checked
  ~ .custom-control-label::before {
  display: none;
}
.checkout-payment-actions .custom-control-label.active::before {
  display: none;
}
.checkout-payment-actions .custom-control-label.active::after {
  color: #a9001c;
  border-color: #a9001c;
  background-color: inherit;
  font-family: 'Font Awesome 5 Free';
  content: '\F058';
  font-weight: 900;
  line-height: 1;
  font-size: 1.3rem;
  outline: 0;
}
.checkout-payment-actions
  .custom-control-input:checked
  ~ .custom-control-label::after {
  color: #a9001c;
  border-color: #a9001c;
}

.header-cart-dropdown .cart-row {
  padding: 18px 35px 5px 15px;
}
/*Loader*/
.loader-main-cls {
  position: relative;
  pointer-events: none;
}

.loader-sub-div {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 99;
}

.loader-sub-div:after {
  width: 35px;
  height: 35px;
  border: 4px solid rgba(30, 29, 29, 0.49);
  border-top-color: #1e1d1d;
  border-radius: 50%;
  position: relative;
  -webkit-animation: loader-rotate 0.3s linear infinite;
  animation: loader-rotate 0.3s linear infinite;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

@-webkit-keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* 24-4-20 */
.member-ship-main {
  border: 2px solid #000;
  border-radius: 10px;
  background: #fff;
  color: #000;
  padding: 20px 15px;
  text-align: left;
  box-shadow: 0 3px 10px 0 rgba(1, 1, 1, 0.3);
  margin: 30px 0 20px;
}
.member-ship-main h3 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 25px;
}
.member-ship-gold h4 {
  position: relative;
  z-index: 1;
  color: #d18029;
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
  margin: 0 0 15px;
}
.member-ship-gold h4 span {
  display: inline-block;
  background: #fff;
  padding: 0 10px;
}
.member-ship-gold h4:after {
  height: 2px;
  width: 100%;
  content: '';
  background: #686766;
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  top: 50%;
  margin-top: -1px;
}
.member-ship-gold p {
  margin-bottom: 5px;
  font-size: 14px;
}
.accmenu_sec .setting_menu_list {
  position: absolute;
}
.membership_status {
  margin-top: -33px;
  position: relative;
}
.membership_status span {
  position: absolute;
  left: 0;
  right: 0;
  top: 16px;
  text-transform: uppercase;
  color: #fff;
}
li.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
  line-height: 23px;
}
.cart-row-header .text-right h6 {
  text-decoration: underline !important;
}
.slfcol-form-block .special-instruction-block h6 {
  padding-left: 0px;
}
.check-order-status-btn-block.order-con-status {
  margin-bottom: 60px;
}
.header-cart-dropdown.show-cart .delivery-info .slfcol-form-block .media {
  margin-bottom: 0.5rem;
}
.acc-order-info {
  pointer-events: none;
}
.header-cart-dropdown .cart-row {
  padding: 18px 35px 18px 15px;
}
.mobile_account_delivery_item li.delivery_mob_menu a i {
  background: url(../img/ico-delivery-white.png) no-repeat center;
  background-size: auto 22px;
  width: 50px;
  height: 35px;
}

.scourt-combo,
ul.count-option-one {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.scourt-combo > li,
.count-option-main ul.count-option-one > li {
  width: 23.5%;
  margin: 0 2% 30px 0;
  border: 1px solid rgba(255, 255, 255, 0);
  padding: 10px;
  position: relative;
  background: #fff;
  display: flex;
  flex-flow: column;
}

.scourt-combo > li.active,
ul.count-option-one > li.active {
  border: 1px solid #c0c0c0;
}

.scourt-combo > li:nth-child(4n),
.count-option-main ul.count-option-one > li:nth-child(4n) {
  margin-right: 0;
}

.sc-main {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}

.sc-main-text {
  padding: 10px 0 0 0;
}
.text_center {
  text-align: center;
}

.sc-main-text h5 {
  margin-bottom: 10px;
}

.single_combo_products .prd_close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 22px;
  height: 22px;
  background: #000;
  border-radius: 50%;
  padding: 0px;
  text-align: center;
  display: none;
  line-height: 20px;
  overflow: hidden;
}

.single_combo_products .prd_close img {
  width: 10px;
}

.modify-flex {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px 0;
}
.both-merge {
  padding: 10px 0 10px;
}
.modify-flex span {
  color: #626262;
  font-weight: bold;
  font-style: italic;
  font-size: 90%;
}

.count-option-main ul.count-option-one li {
  display: flex;
  justify-content: center;
}
.count-option-main ul.count-option-one li .chosen_adn_left {
  padding-bottom: 10px;
  padding-right: 0;
}
.count-option-main ul.count-option-one li .chosen_adn_left img {
}
.count-option-main ul.count-option-one li .chosen_adn_right {
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex-grow: 1;
}
.count-option-main ul.count-option-one li h5 {
  color: #222;
  flex-grow: 1;
  margin: 0 0 10px;
  text-align: center;
}

.scourt-combo > li {
  display: flex;
  flex-flow: column;
}
.scourt-combo > li .sc-main {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}
.scourt-combo > li .sc-main-text {
  padding: 10px 0 0 0;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}
.scourt-combo > li .sc-main-text h5 {
  background: none;
  color: #222;
  display: block;
  flex-grow: 1;
}

.count-option-main ul.combo-widthout-image > li {
  width: 100%;
  background: none;
  flex-flow: row;
  justify-content: space-between;
  margin: 0 0 12px;
  padding: 0;
}
.count-option-main ul.combo-widthout-image > li .chosen_adn_right {
  flex-grow: initial;
}
.count-option-main ul.combo-widthout-image > li .chosen_adn_left {
  max-width: calc(100% - 105px);
  max-width: -webkit-calc(100% - 105px);
  padding-bottom: 0;
}
.count-option-main ul.combo-widthout-image > li .chosen_adn_left h5 {
  margin: 0;
  padding-right: 5px;
  text-align: left;
}

.modal-body .product_detsec .accordion_head h6 {
  color: #ffffff;
}

.modal-body .product_detsec .accordion_head {
  background: #454545;
  margin: 0;
}
.modal-body .product_detsec .accordion_body {
  background: #f9f9f9;
  padding: 20px;
}
.modal-body .product_chosen_item_left_full {
  margin-bottom: 10px;
}
.react-datepicker-popper {
  z-index: 9;
}
.bithday_picker .react-datepicker-wrapper {
  display: block;
}

/* Home Intro Popup */
.modal .intro_popup .close {
  top: -4px;
  right: -4px;
}
.intro_out {
  max-width: 550px;
  margin: 10px auto;
}
.intro_popup {
  text-align: center;
  border: 0;
  padding: 0;
}
.intro_popup .modal-body {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0;
}
.intro_poptt img {
  margin: 0 0 20px;
}
.intro_popcontent h2 {
  font-weight: 400;
  margin: 0 0 20px;
}
.intro_popcontent h2 small {
  display: block;
  font-weight: 600;
  margin: 5px 0 0;
  color: #d0b182;
}
.intro_popaction a {
  display: block;
  max-width: 200px;
  background: #d1d2d4;
  color: #58595b;
  padding: 9px 15px;
  text-align: center;
  margin: 0 auto 15px;
  transition: all 400ms;
}
.intro_popaction a:last-child {
  margin-bottom: 0;
}
.intro_popaction a:hover {
  background: #a9001c;
  color: #fff;
  text-decoration: none;
}
.react-datepicker__time-list-item--disabled {
  display: none;
}
.inner_banner_sec {
  margin-top: 30px;
}

.modal-body .product_detsec .accordion_body .form-group .re_select ul {
  padding: 0;
  list-style: none;
}
/* .modal-body
  .product_detsec
  .accordion_body
  .form-group
  .re_select
  ul
  li
  .select_modifier {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  background: #fff;
  padding: 15px;
} */
.single_combo_products .combo_modifier_product .radio-try {
  width: 100%;
  margin: 0px;
}
.modal-body
  .product_detsec
  .accordion_body
  .form-group
  .re_select
  ul
  li
  .react-select-dropdown
  .react-select-arrow {
  top: 14px;
}

.radio-try {
  margin: 16px 0;
  display: block;
  cursor: pointer;
}
.radio-try input {
  display: none;
}
.radio-try input + span {
  line-height: 22px;
  height: 22px;
  padding-right: 22px;
  display: block;
  position: relative;
}
.radio-try input + span:not(:empty) {
  padding-right: 30px;
}
.radio-try input + span:before,
.radio-try input + span:after {
  content: '';
  width: 22px;
  height: 22px;
  display: block;
  border-radius: 50%;
  right: 0;
  top: 0;
  position: absolute;
}
.radio-try input + span:before {
  background: #000;
  -webkit-transition: background 0.2s ease,
    -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
  transition: background 0.2s ease,
    -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
  transition: background 0.2s ease,
    transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
  transition: background 0.2s ease,
    transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2),
    -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
}
.radio-try input + span:after {
  background: #fff;
  -webkit-transform: scale(0.78);
  transform: scale(0.78);
  -webkit-transition: -webkit-transform 0.6s
    cubic-bezier(0.175, 0.885, 0.32, 1.4);
  transition: -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
  transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
  transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4),
    -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
}
.radio-try input:checked + span:before {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
  background: #a9001c;
}
.radio-try input:checked + span:after {
  -webkit-transform: scale(0.4);
  transform: scale(0.4);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.radio-try:hover input + span:before {
  -webkit-transform: scale(0.92);
  transform: scale(0.92);
}
.radio-try:hover input + span:after {
  -webkit-transform: scale(0.74);
  transform: scale(0.74);
}
.radio-try:hover input:checked + span:after {
  -webkit-transform: scale(0.4);
  transform: scale(0.4);
}

.quandoo-widget-builder {
  margin-bottom: 0;
}
.quandoo-md {
  font-size: 1.5em;
}
.quandoo-s {
  transform-origin: right bottom 0px;
  transform: rotate(-90deg);
  right: 0;
  top: 35%;
  box-shadow: none !important;
}
.quandoo-fixed {
  position: fixed;
  z-index: 999999 !important;
}
.quandoo-round-border {
  border-radius: 5px;
}
.quandoo-button-text {
  padding: 7px 20px;
  box-sizing: border-box;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
}
.quandoo-button,
.quandoo-button-select {
  cursor: pointer;
  background: #000;
  color: #fff;
}
.quandoo-button:hover {
  background: #71001a;
}

/* omise */
.omise-form-error input {
  -webkit-box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0, 0%, 100%, 0),
    0 0 2px 0 rgba(255, 0, 0, 0.5);
  box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0, 0%, 100%, 0),
    0 0 2px 0 rgba(255, 0, 0, 0.5);
  border-color: #ff7076;
  border-top-color: #ff5c61;
}
.pay-omiseconf-popup .alert_height {
  background-color: #f5f5f7;
  border-radius: 10px;
}

.pay-omiseconf-popup {
  max-width: 340px;
}
span.omise-pay-img {
  display: block;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
  margin: 0 auto;
  border: 4px solid #fff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
  position: absolute;
  top: -39px;
  left: 0;
  right: 0;
}

img.makisan-logo-img {
  width: 100%;
  height: 100%;
  -o-object-fit: none;
  object-fit: scale-down;
}

.omise_pay_header {
  position: relative;
  padding: 50px 30px 25px;
  text-align: center;
  border-bottom: 1px solid #d2d2d3;
}
.omise_pay_body {
  padding: 30px;
  position: relative;
  border-top: 2px solid #fff;
}
.omisepop_in .form-control {
  padding: 10px;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  padding-left: 36px;
  margin: 0 0 10px;
}
.expire_row_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.expire_row_inner > div {
  width: 32%;
  position: relative;
}
.omisepop_in .alt_btns .button {
  margin-bottom: 0;
  width: 100%;
  padding: 13px 10px 11px;
  border-radius: 4px;
}
.omisepop_in .form_sec i {
  position: absolute;
  left: 10px;
  color: #888;
  top: 12px;
}
.omisepop_in .form_sec > div {
  position: relative;
}
.omisepop_in .form_sec .uname-box i {
  left: 13px;
}
.omisepop_in .form_sec .card-numbr-div i,
.omisepop_in .form_sec .expire_left i {
  font-size: 16px;
  top: 13px;
}
.omisepop_in .form_sec .expire_right i {
  font-size: 19px;
  top: 12px;
}
.expire_mdl .form-control {
  padding-left: 15px;
}
.pay-omiseconf-popup .card {
  position: absolute;
  display: block;
  right: 10px;
  width: 28px;
  height: 19px;
  background: url(../img/cards/spritecard.png) no-repeat 0;
  top: 10px;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
  background-position: 0 0;
  overflow: hidden;
  border: 1px solid rgb(0 0 0 / 0%);
}
.visa + .card {
  background-position: 0 -19px;
}
.master + .card {
  background-position: 0 -38px;
}
.discover + .card {
  background-position: 0 -57px;
}
.american + .card {
  background-position: 0 -76px;
}
.pay-omiseconf-popup button.mfp-close {
  padding: 0;
  width: 21px;
  height: 21px;
  display: block;
  color: #fff !important;
  border-radius: 50%;
  line-height: 21px;
  background: #a5a5a5;
  border: 1px solid #929292;
  top: 10px;
  font-size: 20px;
  right: 10px;
  text-align: center;
}
.pay-omiseconf-popup button.mfp-close:hover {
  background: #000;
  border-color: #000;
}
.omisepay-mode-option {
  position: fixed;
  top: 20px;
  right: 20px;
  background: #ffd025;
  line-height: 1;
  padding: 9px 11px 8px;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: uppercase;
}
.omise-error-info-div {
  color: #ffffff;
  background-color: #b12f2f;
  border-color: #ebccd1;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  line-height: 1;
}
.omise-error-icon {
  font-size: 14px;
}
.omise_alert_close {
  position: absolute;
  right: 0px;
  color: #fff;
  top: 0;
}
.omise-error-msg {
  margin: 0 0 0 11px;
  font-size: 14px;
}
.omise-error-info-div .container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
}
.omise-pay-btn {
  width: 100%;
}
.omise-pay-btn:hover {
  text-decoration: none;
}
.pay-omiseconf-popup.modal.fade .modal-dialog {
  transform: none;
}
.pay-omiseconf-popup .modal-content {
  border: none;
}

.pay-omiseconf-popup .custom_alert_new {
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 150px;
  width: 350px;
  z-index: 9999;
  display: table;
  height: inherit !important;
}


.new-product-listing .plist_tab{ width: 100%; height: auto; display: none}
.new-product-listing .plist_content{width: 100%; margin: 0}

/*.delivery-section.new-product-listing .plist_tab{ width: 100%; height: auto; display: none;}
.delivery-section.new-product-listing .plist_content{width: 100%; margin: 0}*/

.delivery-section.new-product-listing .menu-top-section-inner{ width: 100%; height: auto; display: block; top: 125px;}
.delivery-section .menu-top-section-inner{width: 100%; height: auto; display: none;}

/*.delivery-section .plist_tab{ width: 100%; height: auto; display: block}
.delivery-section .plist_content{width: 100%; margin: 0}*/

.menu-top-section-inner{position: sticky;
    top: 88px;
    background: #f4f4f4;
    z-index: 9; margin: 0 0 30px 0;}
.menu-top-section-inner .container{ display: flex;
    justify-content: space-between;
    position: relative; }
.menu-top-section-inner  .product-menu > ul .menu-list-intvl > a{  padding: 14px 10px; position: relative; cursor: pointer; color: #000; font-size:16px; text-transform: uppercase;  display:flex; line-height: 1.1;  justify-content: center; align-items: center; height: 100%; text-align: center }

.menu-top-section-inner .menu-list-intvl.active a, .menu-top-section-inner .menu-list-intvl a:hover{background: #a9001c; color: #fff !important; text-decoration: none}
.more_categor_info li {
  cursor: pointer;
}
.more-menu-parent .more-menu-name {
  text-transform: uppercase;
}
.more-menu.active {
  background: #a9001c;    
}
.more-menu.active .more-menu-name {
    color: #fff !important;
    border-bottom: 1px solid #fff;
}
.more_categor_info .menu-title-link {
  text-transform: uppercase;
}
.product-menu {
    display: flex;
    flex-wrap: nowrap;
    padding: 0;
    width: calc(100% - 170px);
}
.product-menu ul {
    display: flex;
    list-style: none;
    flex-wrap: nowrap;
    padding: 0; width: 100%; margin: 0
}
.product-menu ul li{width: 25%;}
.menu-section-left .our-menu-title{display: flex; align-items: center; padding: 11px 5px 11px 25px; font-size: 30px;     border-bottom: 1px solid #eee;}
.menu-section-left .our-menu-title span{ line-height: 0.7;margin-left: 10px; }

ul.more_categor_info {
    padding: 0;
    list-style: none;
    min-width: 280px;
    max-width: 280px;
    background: #efeeee;
    position: absolute;
    right: 0;
    max-height: 60vh;
    overflow-y: auto;
    border-radius: 3px;
    top: calc(100% + 5px);
    border: 1px solid rgba(0,0,0,0.1); display: none;
}
ul.more_categor_info li a{ display: block;
    padding: 12px 20px; border-bottom: 1px solid #f3f3f3;
    color: #0a0b10; text-transform: capitalize;
    font-size: 15px; line-height: 1; color: #000;
}
ul.more_categor_info li:last-child a{border-bottom: 0;}
ul.more_categor_info li a:hover, ul.more_categor_info li.active a{ background: #a9001c; color: #fff; }
.more-menu{ width: 170px }
.more-menu-parent {
    cursor: pointer;
    padding: 10px 28px 10px 5px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    text-transform: capitalize;
    font-size: 16px;
    line-height: normal;
    height: 100%;
}
.more-menu-parent span {border-bottom:1px solid #000;}
label.open_more_category {
    width: 25px;
    text-align: right;
    cursor: pointer;
    font-size: 25px;
    color: #000;
    position: absolute;
    right: 10px; margin: 0;display: block
}
.more-menu-parent:hover{ background: #a9001c; color: #fff;}
.more-menu-parent:hover span{border-color: #fff}
.more-menu-parent:hover label.open_more_category{color: #fff;}

.delivery-section.new-product-listing .menu-top-section-inner .product-mobile-menu-nav{
  display: none;
}





.product-mobile-menu-nav .slick-track{
    background: #fff;
    border-bottom: 4px solid #e7e6e6;
}
.product-mobile-menu-nav .slick-track .slick-slide > div
{display: block; width: 100%;}

.product-mobile-menu-nav .slick-track .slick-slide > div .menu-title-link{
    padding: 10px 2px;
    position: relative;
    cursor: pointer;
    color: #000;
    
    text-transform: capitalize;
    font-size: 17px;
    line-height: normal;
    display: block;
    height: 100%;text-align: center}

.product-mobile-menu-nav .slick-track .slick-slide > div .menu-title-link:before {
    content: "";
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: -4px;
    background: #a9001c;
    height: 4px;
    transition-property: left, right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}
.product-mobile-menu-nav .slick-track .slick-slide > div .active .menu-title-link:before{    left: 0; right: 0;}
.product-mobile-menu-nav .slick-track .slick-slide > div .active .menu-title-link{color: #181818}
.product-mobile-menu-nav{display: none}

.product-mobile-menu-nav button.slick-arrow, 
.products_sidebar_inner button.slick-arrow{
    position: absolute;
    overflow: hidden;
    text-indent: -9999px;
    padding: 0;
    width: 26px;
    height: 26px;
    border-radius: 3px;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s linear all;
    background-size: 100%;
    background: #e8e5df;
}
.product-mobile-menu-nav .slick-prev, .products_sidebar_inner .slick-prev{left: 0}
.product-mobile-menu-nav .slick-next, .products_sidebar_inner .slick-next{right: 0}
.product-mobile-menu-nav button.slick-arrow:before, 
.products_sidebar_inner button.slick-arrow:before{ 
  position: absolute;
    left: 0;
    right: 0;
    width: 14px;
    height: 14px;
    top: 50%;
    margin-top: -8px; 
    background: url(../img/left-new-arrow.png) no-repeat; content: "";
    margin-left: 6px;}
.product-mobile-menu-nav button.slick-arrow.slick-next:before, 
.products_sidebar_inner button.slick-arrow.slick-next:before{
    transform: rotate(180deg);
    margin-top: -6px;
}

.slick-list, .slick-slider {
    position: relative;
    display: block;
}

.menu-section-mobile-inner .slick-track .slick-slide>div .active .menu-title-link:before{
    left: 0;
    right: 0;
}


.more-menu-parent:after{
    content: "";
    background: url(../img/down-arrow.png) no-repeat center;
    top: 15px;
    right: 15px;
    position: absolute;
    pointer-events: none;
    height: 30px;
    width: 30px;
}










.pager-button-block{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  
}


.delivery-section .inner-page-header .page-header-block ul{
  padding: 8px 8px;
  list-style: none;
  margin-bottom:0;
  background: #f4f4f4;
  border-radius: 32px;
}

.delivery-section .inner-page-header .page-header-block ul li{
  display: inline-block;
  
}


.delivery-section .inner-page-header .page-header-block ul li a{
    display: inline-block;
    color: #000;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    padding: 8px 18px;
    font-weight: 500;
    border-radius: 32px;
}

.delivery-section .inner-page-header .page-header-block ul li:hover a{
  background: #050608c2;
  color: #fff;
  transition: color 240ms ease;
}

.delivery-section .inner-page-header .page-header-block ul li.active a{
  background: #050608c2;
  color: #fff;
  transition: color 240ms ease;
}

.new-product-listing .menu-top-section-inner.menu-desktop-sticky{
    position: fixed;
    top: 124px;
    z-index: 9;
}

.new-product-listing .menu-top-section-inner .open_more_categories ul.more_categor_info.active {
    display: block;
}

/*.new-product-listing .spct-menu-list {
  display: none;
}*/
.delivery-section.new-product-listing, .delivery-section.old-product-listing {
    padding-top: 182px;
}

.new-product-listing .main-wrapper, .old-product-listing .main-wrapper {
  padding-top: 0 !important;
}
.product-details-mobile-price { display: none; }
.ios-toolbar-gone .product_detpopup .prd-chosen-sub-col{ transform: translateY(-25px);}
.caFLbO{bottom: 65px !important; }
.gqTMHp, .dFdohC { margin-right: 10px !important; margin-bottom: 0 !important; }

.cart-row-header{
  padding: 18px 0px 5px 15px;
}

.checkout_quantity_show {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart-items-block .cart-row-header .col-pd-2{
  padding-right: 5px;
}

.checkout_quantity_show  p {
    font-size: 15px;
    line-height: 1.5;
    padding-left: 5px;
    font-weight: 600;
}

.know_more_delivery {
    font-weight: 600;
    color: #000;
    padding: 10px 10px;
    background: #efefef;
    border-radius: 5px;
}

#video-popup .modal-body {
    padding: 40px 50px;
    flex-direction: column;
}

#video-popup .intro_popup{
  background: #a9001c;
  color: #fff;
}

#video-popup .hordernow_btn a{
  border: 2px solid #ffff00c4;
  color: #ffff00c4;
  background: #a9001c;
  padding: 12px 22px;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  cursor: pointer;
  transition: all .3s ease;
}

#video-popup .d-lg-inline-block{
  margin-top: 20px;
}

#video-popup .intro_out {
    max-width: 620px;
}

#video-popup .hordernow_btn a:hover{
  border: 2px solid #000;
  background: #000;
  transition: all .3s ease;
}

#video-popup .intro_popup .close{
  right: 0;
}
.brand-logo img {
    width: 100%;
}