/* Min Width */
@media (min-width: 768px) {
  .mobile-category-dropdown {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .mobile-category-dropdown {
    max-width: 960px;
  }
}
@media (min-width: 1024px) {
  .main-wrapper {
    padding-top: 125px;
  }
}
@media (min-width: 1200px) {
  .mobile-category-dropdown {
    max-width: 1170px;
  }
}
@media (min-width: 1300px) {
  #mainNav .navbar-nav > li > a,
  #mainNav .navbar-nav > li > a:focus {
    color: #181818;
  }
}

/* Max Width */
@media (max-width: 1299px) {
  /*  Header */
  header.main-header {
    box-shadow: none;
  }
  .info-text {
    padding: 7px 0;
  }
#mainNav .navbar {
    padding: 0 50px 0 20px;
}
    #mainNav .navbar-nav > li > a {
    padding: 0 9px;
    font-size: 1rem;
}

  /* Product list */
  .plist_tab {
    display: none;
  }
  .plist_content {
    width: auto;
    margin-left: 0;
  }
  .subcategory_tt {
    margin: 0 0 15px;
  }
  .product_detsec .product-info-colm .card-header,
  .product-details .product-info-colm.no-gallery .card-header {
    padding: 0;
  }
  .product_detsec .product-info-colm .card-body {
    padding: 25px 0;
  }
  /* Footer */
  .feedback-form {
    padding-right: 0;
  }
  .navbar-expand-lg .footer-nav {
    flex-direction: initial;
    flex-wrap: wrap;
    justify-content: center;
  }
  .delivery-section.new-product-listing .menu-top-section-inner{  top: 168px;}
  .delivery-section.new-product-listing, { padding-top: 150px; }
  .delivery-section.old-product-listing { padding-top: 200px; }
}
@media (max-width: 1199px) {
    
      .mobilenav_wrapper {
    display: block;
  }
      .main-menu-block {
    display: block;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding: 0 24px 0 0;
  }

  .main-menu-block .navbar {
    display: none;
  }
     .mobile-header-navigation {
        display: block;
    }
       .mobile_accountdel_body {
        display: block;
    }
 
    .main-menu-block .navbar-expand-lg .navbar-collapse{    display: block !important;}
        .header-cart-dropdown {
        top: 74px;
        right: 0;
    }
    .header-cart-dropdown:after {
        right: 36px;
    }
    #mainNav {
        height: auto;
        border-bottom: 1px solid #ffffff;
    }
    #mainNav .navbar-nav>li {
        margin-left: 1rem;
    }
    #mainNav .navbar-toggler.navbar-toggler-close {
        margin-left: 0rem;
    }
    .menu-section {
        position: relative;
    }
    header .ecoupons-nav-item {
        display: block;
    }
    #mainNav .mobile-xl {
        padding-left: 0px;
        padding-right: 15px;
    }
    .sprcrt-logo-colm {
        padding: 0rem
    }
    .mobile-menu {
        display: block;
        position: relative;
    }
    .mobile-menu button i.fas {
        display: none;
    }
    .mobile-menu button {
        border: 0px solid #9d2751;
        background: #fff;
        color: #9d2751;
        font-size: 38px;
        padding: 0;
        border-radius: 0px;
        background-image: url('../img/mobile-menu.png');
        background-repeat: no-repeat;
        background-size: 70%;
        width: 58px;
        height: 40px;
        margin-top: 26px;
    }
    #navbarResponsive {
        position: fixed;
        top: 0;
        left: -280px;
        z-index: 1100;
        width: 280px;
        height: 100%;
        background-color: #9d2751;
        overflow: auto;
        transition: 0.5s;
    }
    .mobile-menu-close {
        text-align: left;
        display: block;
        padding: 10px;
        height: 55px;
        position: relative;
    }
    .mobile-menu-close div {
        cursor: pointer;
        width: 36px;
        height: 36px;
        color: #f9caca;
        border: 1px solid #f9caca;
        border-radius: 50%;
        font-size: 18px;
        display: inline-block;
        text-align: center;
        line-height: 36px;
        position: absolute;
        right: 8px;
    }
    #navbarResponsive:after {
        display: none;
        content: "";
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: 0.5s;
        z-index: -1;
    }
    .navbar-collapse.mobile-nav-open {
        left: 0px !important;
    }
    .navbar-collapse.mobile-nav-open:after {
        opacity: 1;
        display: none;
    }
    .menu-overlay {
        display: none;
        background-color: #000;
        bottom: 0;
        left: 0;
        opacity: 0.5;
        filter: alpha(opacity=50);
        /* IE7 & 8 */
        position: fixed;
        right: 0;
        top: 0;
        z-index: 49;
    }
    .top-header {
        height: auto;
    }
    
    .top-header .inner-header-left p {
        font-size: 0.8rem;
    }
    #mainNav .navbar-nav>li>a, #mainNav .navbar-nav>li>a:focus {
        color: #ffffff;
    }
     .search-toggle {
        width: 40px;
    }
    #mainNav .navbar-toggler {
        margin-left: 1rem;
    }
    .main-menu-block .navbar-expand-lg .navbar-collapse {
        display: block !important;
    }
    .main-menu-block .navbar-expand-lg .navbar-nav {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }
    #mainNav .navbar-nav>li>a, #mainNav .navbar-nav>li>a:focus {
        color: #ffffff;
        padding: 10px 0px;
        text-decoration: none;
        border-bottom: 0;
    }
    .order-now-box {
        border-right: 0px solid #ffffff;
        width: 50%;
    }
    #mainNav .navbar-nav>li {
        margin-left: 8px;
        line-height: 1.5rem;
    }
        #mainNav .navbar-brand {
        margin-right: 0;
        text-align: center;
        width: 100%;
        height: 100%; justify-content: center;
    }
    .mobile-menu {
    height: 100%;
  }
  .mobile-menu button {
    height: 100%;
    background-position: center left;
    margin-top: 0;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: column;
  }
  .mobile-header-navigation .navbar-expand-lg .navbar-collapse {
    display: block !important;
  }
  #mainNav .navbar-brand {
    display: flex;
    align-items: center;
  }
  #mainNav .navbar-nav > li > a:hover,
  #mainNav .navbar-nav > li > a:focus:hover {
    color: #fdcc52;
  }
  .mobile-category-dropdown {
    margin: 0 auto;
    right: 0;
  }
  .mobile-category-dropdown .dropdown {
    box-shadow: none;
  }

  .mobile_mainacc_menulist > ul {
    list-style: none;
  }
  .cart-search-box {
    float: none;
  }
  .mainmenu-right {
    text-align: right;
    padding-top: 2rem;
    padding-bottom: 15px;
  }
  .mainmenu-right .hordernow_btn {
    display: none !important;
  }
      .mainmenu-right {
        position: relative;
        height: 100%;
    }
     .top-header-account.d-lg-inline-block {
        display: none !important;
    }
    .top-header-account {
        float: left;
        width: 50%;
        padding: 0.5rem 1rem;
        height: 40px;
    }
    .header-ordernow-btn {
        width: 100%;
    }
    .navbar-light .mobile-cart {
        display: block !important;
        position: relative;
    }
    .top-header {
        height: auto;
        display: none;
    }
        .cart-search-box {
        float: right;
    }
    
  /* Product list */
  .delivery-section section.inner-page-header {
    padding: 25px 0;
  }
  .delivery-section .page-header-block h1.font-gothamlight {
    font-size: 41px;
  }
  .delivery-section .page-header-block h1.font-gothamlight:before,
  .delivery-section .page-header-block h1.font-gothamlight:after {
    height: 1px;
  }
  .subcategory_tt h3 {
    font-size: 18px;
  }
  /* Popup */
  .delivery-section
    .spct-menu-list
    .product-item-colm
    .card-body
    .btn.add-to-btn-cart {
    min-width: initial;
    width: 100%;
  }
  /* Footer */
  footer .footer-quicklinks-container {
    padding-top: 1.5rem;
  }

  .intro_out {
    max-width: 550px;
  }


}
@media (max-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .footer-nav .nav-link {
    padding: 5px 10px;
    line-height: normal;
  }
}
@media (max-width: 991px) {
  /* Product List */
  .product_slidersec .product-slide {
    height: 350px;
  }
  .delivery-section .page-header-block h1.font-gothamlight {
    font-size: 32px;
  }
  .product-details .card-header h5.pdet_tt {
    font-size: 28px;
    line-height: 1.3;
  }
  .pdet_off {
    font-size: 16px;
    width: 75px;
  }
  .product_detsec .product_info {
    padding-top: 20px;
  }
  .product_detsec .accordion_head {
    padding-left: 15px;
  }
  .product_detsec .accordion_body {
    padding-left: 15px;
  }
  /* Popup */
  .popup_left {
    min-height: initial;
  }
  .delivery-datetime-modal .modal-body .ordertype_address {
    padding: 40px 0;
  }
  .ordertype_address h1 {
    font-size: 28px;
  }
  .ordertype_address .label-text {
    font-size: 17px;
  }
  .ordertype_list li .btn {
    font-size: 18px;
  }
  .delivery-datetime-modal .popup_right {
    padding: 40px 25px;
  }
  .scourt-combo > li,
  .count-option-main ul.count-option-one > li {
    width: 31.33%;
  }
  .count-option-main ul.combo-widthout-image > li {
    width: 100%;
  }
  .scourt-combo > li .sc-main-text h5,
  .count-option-main ul.count-option-one li h5 {
    font-size: 14px;
  }
  /* Footer */
  footer p .nav-header {
    padding: 10px 0 0;
  }
  .feedbackform_submit .btn {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .btn_normal {
    height: 45px;
    padding: 8px 15px;
  }
  /* Header */
  .info-text {
    font-size: 12px;
  }
  .mobile-category-dropdown {
    max-width: none;
  }
  .cart-info h6 {
    font-size: 13px;
  }
  .table_list li {
    font-size: 12px;
  }
  /* Product list */
  .delivery-section .page-header-block h1.font-gothamlight {
    font-size: 28px;
  }
  .product_detsec {
    padding: 20px 0 0;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 0;
  }
  .product_detsec .product-choosen-item {
    margin: 0 0 30px;
  }
  .product_detsec .product-info-colm .card-body {
    padding-bottom: 0;
  }
  .product-details .card-header h5.pdet_tt {
    font-size: 24px;
  }
  .product-more-slider .slick-next,
  .product-more-slider .slick-prev {
    width: 42px;
    height: 51px;
    background-size: 100%;
  }
  /* Popup */
  .popup_right .syd_date {
    width: 100%;
    margin: 0 0 8px;
  }
  .popup_right .syd_time {
    width: 100%;
  }
  .popup_right .syd_date .react-datepicker-wrapper,
  .popup_right .syd_time .react-datepicker-wrapper {
    width: 100%;
  }
  .delivery-datetime-form-block .btn {
    height: 45px;
    font-size: 16px;
  }
  .product_detpopup .prd-chosen-sub-item-right button {
    padding: 13.5px 15px;
    font-size: 18px;
  }
  .scourt-combo > li,
  .count-option-main ul.count-option-one > li {
    width: 48%;
  }
  .count-option-main ul.combo-widthout-image > li {
    width: 100%;
  }

  /* Footer */
  .feedbackform_submit .btn {
    text-align: center;
  }

  .intro_out {
    max-width: 100%;
    margin: 0;
  }
}
@media (max-width: 600px) {
  /* Header */
  .calctrcart-footer p {
    font-size: 0.8rem;
  }

  .react-datepicker-wrapper {
    display: block;
  }
  .slfcol-form-block .syd_merge {
    margin-left: 0;
    margin-right: 0;
  }
  .slfcol-form-block .syd_date {
    width: 100%;
    padding: 0;
  }
  .slfcol-form-block .syd_time {
    width: 100%;
    padding: 0;
  }
}
@media (max-width: 580px) {
  .mobile_account_delivery_item a {
    padding-left: 70px;
    font-size: 12px;
  }
  /*13-5-20*/
  .unit-address .col.col-xl-4 {
    flex-basis: auto;
    margin-bottom: 20px;
  }
  .unit-address .col.col-xl-4:last-child {
    margin-bottom: 0px;
  }

    #productModalCenter .modal-content {    
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 576px) {
  /* Product list */
  .product_slidersec .product-slide {
    height: 300px;
  }
  .pdet_ttrow {
    display: block;
  }
  .product-details .card-header h5.pdet_tt {
    margin: 0 0 15px;
  }
  .pdet_rating {
    text-align: left;
    margin: 0 0 15px;
  }
    .modal-dialog{ margin: 0}
    .product_detpopup .prd-chosen-sub-item-left .qty-bx { margin: 0 10px 0 0;}    
    .product_detpopup .prd-chosen-sub-col { z-index: 9; display: flex;  padding: 10px;  background: #ffffff; position: fixed;  bottom: 0; left: 0; right: 0;  border-top: 1px solid #e4e4e4; justify-content: space-between; }    
    .product_detpopup .prd-total-price{margin-bottom: 0; font-size: 24px;}    
    .product_detpopup .qty-bx span{height: 45px; line-height: 45px;}
    .product_detpopup .qty-bx{height: 45px;}

  .product-details-noraml-price {  display: none; }
  .product-details-mobile-price { display: block; }
  .addtocart-main { display: flex;}
  .product_detpopup .product_info{ margin-bottom: 70px; }
}
@media only screen and (max-width: 520px) {
  #forgetPasswordModalCenter .pop-whole-lhs-inner h2.font-gothamlight,
  #signInModalCenter .pop-whole-lhs-inner h2.font-gothamlight {
    font-size: 18px;
    padding: 0px 50px;
  }
}
@media (max-width: 480px) {
  /* Header */
  .cart-img {
    width: 100%;
    margin: 0 0 10px;
  }
  .cart-info {
    width: 100%;
  }
  .cart-info h6 {
    font-size: 12px;
    margin: 0 0 8px;
  }
  .cart_sub_items {
    padding-top: 0;
  }
  .table_list li {
    font-size: 11px;
  }
  .cart-right {
    text-align: left;
    margin-top: 10px;
  }

  .scourt-combo > li,
  .count-option-main ul.count-option-one > li {
    width: 100%;
    margin: 0 0 15px;
  }
  .count-option-main ul.combo-widthout-image > li {
    width: 100%;
    margin: 0 0 12px;
  }

  .intro_popup {
    padding: 0;
  }
  .intro_poptt p br {
    display: none;
  }
  .intro_popcontent h2 br {
    display: none;
  }
  .product_detpopup .prd-chosen-sub-item-right {
    margin-top: 0;
    letter-spacing: 0;
  }
  @supports (-webkit-overflow-scrolling: touch) {
    .product_detpopup .prd-chosen-sub-col {
      padding-bottom: 50px;
    }
    .mobile-product-details-res .eapp-whatsapp-chat-root-layout-component {
        display: none;
    }
  }
}

@media (max-width: 420px) {
  .sign-up-modal .modal-body .pop-whole .inside-popup-rhs .popup-header,
  .sign-in-modal .modal-body .pop-whole .inside-popup-rhs .popup-header {
    padding-left: 0;
    padding-right: 0;
  }
  .btn.fb-button {
    padding: 15px 15px;
    font-size: 0.9rem;
  }
  .btn.fb-button i {
    margin: 0;
  }
  .delivery-datetime-modal .modal-body .ordertype_address {
    padding: 25px 0;
  }
  .delivery-datetime-modal .popup_right {
    padding: 25px 15px;
  }
}
@media (max-width: 380px) {
  .two-links a {
    font-size: 12px;
  }
  .spilter {
    height: 10px;
    margin: 0px 8px 0px;
  }
  .mobile_account_delivery_item a {
    padding-left: 60px;
  }
  .modal-body .product_detsec .accordion_body {
    padding: 10px;
  }
}
@media (max-width: 350px) {
  .two-links a {
    font-size: 10px;
  }
}


@media (min-width: 1500px){
  .more-menu-parent:after{
    top: 8px;
  }
}
@media (max-width: 1298px) {
  .top-menu-nav .dropdown-item  {
    white-space: pre-wrap;
  }

  .new-product-listing .menu-top-section-inner{
    top: 167px;
  }
}

@media (max-width: 1020px){
  .delivery-section.new-product-listing .menu-desktop-section{
    display: none;
  }

  .delivery-section.new-product-listing .menu-top-section-inner .product-mobile-menu-nav{
    display: block !important;
    max-width: 960px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
  }

  .delivery-section.new-product-listing .menu-top-section-inner{
    background: #fff;
    top: 172px;
  }

  .product-mobile-menu-nav .slick-track .slick-slide > div .menu-title-link{font-size: 15px; display: flex; justify-content: center;
     align-items: center;}
}

@media (max-width: 991px){
  .suresh{
    flex-direction: column;
  }
}

@media (max-width: 990px) {
  .delivery-section.new-product-listing .menu-top-section-inner .product-mobile-menu-nav {
    max-width: 720px;
  }
}

@media (max-width: 780px){
  .delivery-section.new-product-listing .menu-top-section-inner .product-mobile-menu-nav{
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (max-width: 767px){
  .suresh{
    flex-direction: row;
    justify-content: center;
  }

  #video-popup .intro_out{
    margin: 0 auto;
  }

  #video-popup .modal-body {
    padding: 30px 30px;
  }

  #video-popup .intro_popup{
    margin: 0 6px;
  }
}

@media (max-width: 620px){
  .delivery-section.new-product-listing .menu-top-section-inner {
      top: 170px;
  }
}

@media (max-width: 580px){
  .delivery-section.new-product-listing .menu-top-section-inner {
      top: 160px;
  }
}

@media (max-width: 480px){
  .suresh {
    justify-content: flex-start;
  }

  #video-popup .modal-body p iframe{
    height: 160px;
  }

  #video-popup .modal-body {
    padding: 30px 10px;
  }

  #video-popup .modal-body p span{
    font-size: 15px !important;
  }
}
