/* App Responsive */

/*!
 * Spring Court 
 */

/* 
  ##Device = Desktops
  ##Screen = 1024px to higher resolution desktops
*/

@media (max-width: 1679px) {
    .bakery_row_fixed {
        max-width: 1340px;
    }
}

@media (min-width: 1024px) {
    .mainmenu-right {
        float: right;
    }
    .sprct-feature-item-box .box-content {
        padding: 20% 0.5rem;
    }
    .main-wrapper {
        padding-top: 5.5rem;
    }
    .cart-info {
        width: calc(100% - 134px);
    }
    
}

/* 
  ##Device = Tablets, Ipads 
  ##Screen = B/w max 1024px
*/

@media (max-width: 1200px) {
    .modal-open .modal {
        padding-right: 0px !important
    }
    .modal-lg, .modal-xl {
        max-width: 97%;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1040px;
    }
    .myaccount-navbar {
        background: #e6e3e3;
        padding: 0rem 20%;
    }
}

@media (max-width: 1299px) {
   

    .cart-items-block {
        padding: 1.9rem 1.9rem;
    }
    .cart-row [class*=col-] {
        padding: 0px 15px 0px 0px;
    }
    .redeem-promocode-row.row .badge {
        position: static;
        font-size: 0.8rem;
        padding: 0.6rem;
    }
    
    section {
        padding: 25px 0px 0px 0;
    }
   
    .page-header-block {
        padding: 2rem 0px;
    }
    .page-header-block h1.font-azakie, .page-header-block h1.font-azakie {
        font-size: 2rem;
    }
    .spct-menu-list {
        padding: 0rem 0rem;
    }
    .product-details .product-info-colm .card-header {
        padding: .75rem 1rem;
    }
    .product-details .product-info-colm .card-body {
        padding: 2.5rem 1rem;
    }
    .choosen-product-options-colm {
        padding: 1rem 1rem;
    }

 
    .main-wrapper {
        padding-top: 113px;
    }
    .setting_menu_list {
        display: none;
    }
    .menu-category-block .slick-slider {
        display: none;
    }
    .mobile-category-dropdown {
        display: block; height: auto;
    }
    .sprct-feature-item-box .title {
        font-size: 1.2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .sprct-feature-item-box .box-content {
        padding: 2rem 0.5rem;
    }
    .sprct-feature-item-box p {
        margin-bottom: 0.4rem;
    }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .main-wrapper {
        padding-top: 8.5rem;
    }
    .header-cart-dropdown {
        right: 0px;
    }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
  ##Device = Tablets, Ipads   ##Screen = B/w 992px to 1024px
*/

@media (min-width: 992px) and (max-width: 1024px) {
    .myaccount-orders-block .order-info-block .checkout-block-header h4 {
        padding: 0.3rem 1rem;
        font-size: 1rem;
    }
    .promotion-info-modal .promotionBrandLogo img {
        max-width: 75%;
    }
}

/* 
  ##Device = Low Resolution  Mobiles 
  ##Screen = B/w to 1199px
*/

@media (max-width: 1299px) {
    .mainmenu-right .d-lg-none {
        display: block !important;
    }
    .order-now-box {
        display: none !important;
    }

   

}

/* 
  ##Device = Low Resolution  Mobiles 
  ##Screen = B/w to 991px
*/

@media (max-width: 991px) {
    .sign-up-modal .modal-body .pop-whole {
        flex-direction: column !important;
        flex-direction: column !important
    }
    .sign-up-modal .modal-body .pop-whole .pop-whole-lhs {
        position: static;
        transform: none !important;
        -webkit-transform: none!important;
        padding: 50px 0px;
    }
    .sign-up-modal .modal-body .pop-whole .pop-whole-lhs .pop-whole-lhs-inner {
        max-width: 100%;
    }
    .sign-up-modal .modal-body .pop-whole .inside-popup-rhs {
        float: none;
        max-width: 100%;
        position: static;
    }
    .sign-up-modal .modal-body .pop-whole .inside-popup-rhs .close {
        top: 0px;
        right: 0px;
    }
    .sign-in-modal .modal-body .pop-whole {
        flex-direction: column !important;
        flex-direction: column !important
    }
    .sign-in-modal .modal-body .pop-whole .pop-whole-lhs {
        position: static;
        transform: none !important;
        -webkit-transform: none!important;
        padding: 50px 0px;
    }
    .sign-in-modal .modal-body .pop-whole .pop-whole-lhs .pop-whole-lhs-inner {
        max-width: 100%;
    }
    .sign-in-modal .modal-body .pop-whole .inside-popup-rhs {
        float: none;
        max-width: 100%;
        position: static;
    }
    .sign-in-modal .modal-body .pop-whole .inside-popup-rhs .close {
        top: 0px;
        right: 0px;
    }
    /* Myaccount Page */
    .account_sec {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .accmenu_sec {
        width: 100%;
    }
    .accsec_right {
        width: 100%;
        padding: 20px 15px;
    }
    .mainacc_menuout {
        margin: 0 0 10px;
    }
    .mainacc_menuico {
        display: block;
        position: relative;
        padding: 9px 0 9px 50px;
        color: #191919;
        font-size: 17px;
        cursor: pointer;
        overflow: hidden;
        z-index: 1;
        font-family: 'Berthold Akzidenz-Grotesk', sans-serif;
    }
    .mainacc_menuico b {
        font-weight: normal;
    }
    .mainacc_menuico:after {
        content: "";
        position: absolute;
        left: 0;
        top: -100%;
        width: 100%;
        height: 100%;
        background: #ecdfcd;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        z-index: -1;
    }
    .mainacc_menuico.active:after {
        top: 0;
    }
    .mainacc_menuico i {
        position: absolute;
        left: 1px;
        top: 1px;
        font-size: 18px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        background: #ecdfcd;
        text-align: center;
        -webkit-box-shadow: 0 0 1px 0 #555;
        box-shadow: 0 0 1px 0 #555;
    }
    .mainacc_menuico i:before {
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        display: block;
    }
    .mainacc_menuico.active i:before {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    .mainacc_menulist {
        display: none;
    }
    .mainacc_menulist>li {
        display: block;
        width: auto;
        border-left: 0;
        text-align: left;
        border-top: 1px solid #ddd1c0;
    }
    .mainacc_menulist>li>a {
        text-transform: none;
        padding: 10px 15px 10px 50px;
        background: #ecdfcd;
        color: #191919;
    }
    .mainacc_menulist>li>a:after {
        display: none;
    }
    .promotions-container .address.promo {
        padding: 1.5rem 0.5rem;
    }
    .promoBtn button {
        font-size: 0.7rem;
        padding: .375rem .5rem;
    }
    .promoContent .txt3 {
        margin-bottom: 12px;
    }
}

/* 
  ##Device = Low Resolution  Mobiles 
  ##Screen = A/w to 480px
*/

@media (min-width: 580px) {
    .myaccount-orders-block .order-info-block .checkout-block-header h4 {
        font-size: 1.5rem;
    }
    .mobile-category-dropdown {
        top: 139px;
    }
    .promotionBrandLogo:after {
        border-top: 62px solid #f6f6f6;
        border-left: 28px solid #fff;
        border-bottom: 62px solid #f6f6f6;
    }
    .promotionBrandLogo:before {
        top: 48px;
    }
    .promotion-info-modal .promoContent .txt1 {
        font-size: 1.2rem;
    }
    .promotion-info-modal .promoContent .txt2 {
        font-size: 2.2rem;
    }
    .promotion-info-modal .promoContent .txt3 {
        font-size: 1rem;
    }
    .promotion-info-modal .promotionBrandLogo:after {
        border-top: 84px solid #f6f6f6;
        border-left: 36px solid #fff;
        border-bottom: 84px solid #f6f6f6;
    }
    .promotion-info-modal .promotionBrandLogo:before {
        top: 71px;
    }
}

/* 
  ##Device = Low Resolution  Mobiles 
  ##Screen = A/w to 480px
*/

@media (min-width: 481px) and (max-width: 1299px) {
    .logo-colm {
        padding: 0rem;
        flex: 0 0 60%;
        max-width: 60%;
    }

}

/* 
  ##Device = Low Resolution  Mobiles 
  ##Screen = A/w to 480px
*/

@media (max-width: 420px) {
    .two-links a {
        font-size: 13px;
    }
    .spilter {
        height: 10px;
        margin: 0px 10px 0px;
    }
}

/* 
  ##Device = Low Resolution  Mobiles 
  ##Screen = B/w to 991px
*/

@media (min-width: 768px) and (max-width: 991px) {
    .promotionBrandLogo:after {
        border-top: 59.5px solid #f6f6f6;
        border-left: 28px solid #fff;
        border-bottom: 59.5px solid #f6f6f6;
    }
    .promotionBrandLogo:before {
        top: 44px;
    }
    .promotion-info-modal .promotionBrandLogo:after {
        border-top: 85px solid #f6f6f6;
        border-left: 36px solid #fff;
        border-bottom: 85px solid #f6f6f6;
    }
}

/* 
  ##Device = Low Resolution  Mobiles 
  ##Screen = B/w to 767px
*/

@media (max-width: 767px) {
    .container {
        max-width: 100%;
    }
    .special-promo-modal .form-block {
        padding: 0;
        padding-top: 20px;
    }
    .order-option-list li {
        margin-bottom: 10px;
    }
    .promo-banner img {
        height: 250px;
    }
    .sign-up-modal .modal-body .pop-whole .inside-popup-rhs, .sign-in-modal .modal-body .pop-whole .inside-popup-rhs {
        padding: 0px 15px;
    }
    .sign-up-modal .modal-body .pop-whole .inside-popup-rhs .popup-header, .sign-in-modal .modal-body .pop-whole .inside-popup-rhs .popup-header {
        padding: 1.5rem 1.8rem 0rem 1.8rem;
    }
    .sign-up-modal .modal-body .pop-whole .pop-whole-lhs, .sign-in-modal .modal-body .pop-whole .pop-whole-lhs {
        padding: 30px 0px;
    }
    .myaccount-navbar .nav-tabs .nav-item .nav-link {
        font-size: 0.8rem;
    }
    .home-dinein-section .banner-block img {
        top: 0rem;
        left: 0rem;
    }
    .promotion-info-modal .promotionBrandLogo img {
        max-width: 100%;
    }
    .promotionBrandLogo {
        padding: 20px 5px;
    }
    .mobile_account_menu_click i.mouse-myaccount {
        width: 22px;
        height: 22px;
        left: 10px;
    }
    
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
    .prd-more-item-block .prd-more-item-list {
        padding: 3rem 3rem;
    }
    .promoContent .txt2 {
        font-size: 1.2rem;
    }
    .promoContent .txt3 {
        margin-bottom: 10px;
        font-size: 0.6rem;
    }
    .promotionBrandLogo {
        padding: 10px;
    }
    .mobile-category-dropdown {
        top: 134px;
    }
    .promotionBrandLogo:after {
        border-top: 56px solid #f6f6f6;
        border-left: 28px solid #fff;
        border-bottom: 56px solid #f6f6f6;
    }
    .promotionBrandLogo:before {
        top: 44px;
    }
    .main-wrapper {
        padding-top: 133px;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .react-datepicker-wrapper .form-control {
        padding: 5px;
    }
    .header-ordernow-btn {
        position: relative;
        padding: 0.5rem 0.7rem 0.6rem 2.5rem;
        font-size: 15px;
    }
    .top-header-account {
        padding: 0.4rem 3px;
        font-size: 11px;
        font-weight: bold;
    }
    .order-now-box {
        border-right: 1px solid #ffffff;
    }
    .product-details .product-review-block {
        margin-left: 0px;
        margin-top: 5px;
        display: inline-block;
    }
    section.master-slider {
        padding: 0px 0px 0px 0px;
    }
    .master-slider .bx-wrapper .bx-controls-direction a {
        width: 40px;
        height: 50px;
    }
    .master-slider .bx-wrapper .bx-prev, .master-slider .bx-wrapper .bx-next {
        background-size: 100%;
    }
    .master-slider .bx-wrapper .bannercaption h1 {
        font-size: 1.5rem;
    }
    .master-slider .bx-wrapper .bannercaption h1.font-gothamlight:after, .master-slider .bx-wrapper .bannercaption h1.font-gothamlight:before {
        width: 0px;
    }
    .slick-next, .slick-prev {
        width: 32px;
        height: 41px;
        background-size: 100% !important;
    }
    .prd-more-item-block .prd-more-item-list {
        padding: 3rem 1rem;
    }
    .prd-more-item-list .slick-prev {
        left: -15px;
    }
    .prd-more-item-list .slick-next {
        right: -15px;
    }
    .slfcol-form-block {
        padding: 2.756rem 1.5rem;
    }
    .react-datepicker-wrapper {
        display: inline-block;
        width: 50%;
    }
    .outlet-list-block-right {
        padding: 1rem;
    }
    .cart-items-block {
        padding: 1.9rem 1rem;
    }
    .redeem-promocode-row.row .badge {
        position: relative;
        font-size: 0.7rem;
    }
    .product-details .product-info-colm {
        padding: 1.5rem 1rem 0rem 1rem;
    }
    .product-details .product-info-colm.no-gallery {
        padding: 2.5rem 1rem 0rem 1rem;
    }
    .redeem-promocode-row .left-col-of-redeem-points, .redeem-promocode-row .right-col-of-promo-code {
        padding: 1rem;
    }
    .checkout-payment-actions .custom-control {
        width: 100%;
        margin-bottom: 0.5rem;
    }
    .outlet-list-option-modal .outlet-modal-title img {
        float: none;
        position: inherit;
        left: inherit;
        top: inherit;
    }
    .outlet-list-option-modal .location-find-btn {
        max-width: 270px;
        width: 270px;
    }
    .outlet-list-option-modal .location-find-btn:before {
        left: 1.2rem;
    }
    .prd-chosen-sub-item-left {
        width: 50.118056%;
    }
    .prd-chosen-sub-col {
        padding: 1rem;
    }
    .prd-chosen-sub-item-right {
        width: 100%;
        margin-top: 0.3rem;
    }
    .myaccount-navbar .nav {
        display: block;
    }
    .myaccount-navbar .nav .nav-item {
        border-bottom: 1px solid #f7f2f2;
    }
    .myaccount-orders-block .nav-tabs .nav-link {
        padding: .5rem 0rem;
    }
    .myaccount-orders-block .nav-tabs .nav-link span {
        padding: 0.5rem 0rem;
        font-size: 0.8rem;
    }
    .order-filter-option span.filter-text-lbl {
        font-size: 0.8rem;
    }
    .myaccount-orders-block .tab-content {
        padding: 1.5rem 0.5rem;
    }
    .promoContent .txt2 {
        font-size: 1.6rem;
    }
    .redeemMain .btn-group {
        width: 100%;
        padding: 1rem;
    }
    .myaccount-orders-block .order-info-block .checkout-block-header h4 {
        padding: 0.3rem 1rem;
        font-size: 1rem;
    }
    .order-amount-row p {
        font-size: 1rem;
    }
    .form-block .input-group {
        border: 0px solid #fff !important;
    }
    .form-block .input-group>.form-control {
        width: 100%;
    }
    .form-block .input-group-append {
        margin-left: 0px;
        width: 100%;
        margin-top: 0.5rem;
    }
    .form-block .btn {
        width: 100%;
    }
    .custom_alert {
        left: 1%;
        right: 1%;
        width: 98%;
    }
    .custom_alert .btn {
        width: inherit;
    }
    .redeem-promocode-row .input-group-append {
        width: 100%;
        height: 52px;
        margin-left: 0px;
    }
    .redeem-promocode-row .input-group-append .btn {
        width: 100%;
    }
    .cartaction-checkout, .cartaction-price {
        font-size: 1rem;
    }
    .calctrcart-footer-totrow p, .calctrcart-footer-totrow .col-4.text-right {
        font-size: 1.2rem;
    }
    .product-details .product-info-colm .card-footer {
        padding: 0px;
    }
    .logo-colm {
        padding: 0;
    }
    .cart-row [class*=col-] {
        padding: 0px 15px 0px 0px;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .home-featured-dishes-section .slick-initialized .slick-slide {
        padding: 0rem;
    }
    .promotionBrandLogo:after {
        border-top: 61px solid #f6f6f6;
        border-left: 28px solid #fff;
        border-bottom: 61px solid #f6f6f6;
    }
    .promotionBrandLogo:before {
        top: 49px;
    }
    .promotion-info-modal .promotionBrandLogo:after {
        border-top: 76.5px solid #f6f6f6;
        border-left: 36px solid #fff;
        border-bottom: 76.5px solid #f6f6f6;
    }
    .promotion-info-modal .promotionBrandLogo:before {
        top: 64px;
    }
}

@media only screen and (max-width: 767px) {
    .h1, h1 {
        font-size: 1.7rem
    }
    .h2, h2 {
        font-size: 1.4rem
    }
    .h3, h3 {
        font-size: 1.25rem
    }
    .h4, h4 {
        font-size: 1rem
    }
    .h5, h5 {
        font-size: 0.9rem
    }
    .h6, h6 {
        font-size: 0.8rem
    }
    .menu-overlay {
        display: none;
        background-color: #000;
        bottom: 0;
        left: 0;
        opacity: 0.5;
        filter: alpha(opacity=50);
        /* IE7 & 8 */
        position: fixed;
        right: 0;
        top: 0;
        z-index: 49;
    }
    .top-header {
        height: auto;
    }
    .top-header-left {
        width: 100%;
    }
    .top-header .inner-header-left p {
        font-size: 0.8rem;
    }
    #mainNav .navbar-nav>li>a, #mainNav .navbar-nav>li>a:focus {
        color: #ffffff;
    }
    .master-slider .mst-slide {
        height: 300px;
    }
    .home-about-section .left-colm.content-block, .home-dinein-section .right-colm .content-block {
        text-align: left;
        margin-bottom: 1rem;
        margin-top: 2rem;
    }
    .redeem-promocode-row .left-col-of-redeem-points {
        margin-bottom: 1rem;
    }
    .setting_menu_list {
        position: static;
    }
    .searchbox {
        width: 80vw;
    }
    .home-featured-dishes-section .header-line span:before, .home-featured-dishes-section .header-line span:after {
        top: 18px;
    }
    .mobile_account_item_login {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .mobile_account_item {
        height: 100%;
    }
    .mobile_account_menu_click {
        padding: 15px 10px 15px 45px;
        height: 100%;
    }
    .mobile_account_menu_click i.arrow_myaccount {
        width: 35px;
        height: 100%;
    }
    .mobile_account_item_login > a {
        font-size: 16px;
    }
    .kDrLDz{ bottom: 80px !important }
    .hthdEA, .eqUYtn{ margin-left: 0px !important; margin-right:5px !important; margin-bottom: 0px !important; }
}


@media (max-width: 580px) {    
    .mobile_account_item .mobile_account_item_loggedin>a {
        padding: 15px 35px 15px 48px;
        font-size: 13px;
    }
    .mobile_account_item .mobile_account_item_loggedin>a>i {
        width: 28px;
        height: 28px;
    }
    .mobile_mainacc_menutrigger {
        background-size: 16px auto;
        width: 46px;
        height: 42px;
    }
    .mobile_mainacc_menulist li a {
        padding: 15px 10px 15px 50px;
        font-size: 13px;
    }
    .mobile_mainacc_menulist .setting_menu_list_icon {
        left: 20px;
    }
    .mobile_account_item_login>a {
        padding: 15px 0 15px 45px;
        font-size: 11px;
    }
    .mobile_account_item_login>a>i {
        width: 28px;
        height: 28px;
        left: 10px;
    }
    .mobile_account_menu_click {
        font-size: 11px;
    }
    .mobile_account_menu_click i.arrow_myaccount {
        background-size: 16px auto;
    }
   
    .mobile_account_menu_click i.scotter-myaccount {
        width: 40px;
        height: 28px;
        background-size: auto 28px;
    }
    .mobile_account_menu_click i.takeaway-myaccount {
        width: 40px;
        height: 28px;
        background-size: auto 28px;
    }
    .mobile_account_delivery_item a {
        padding-left: 60px;
        font-size: 13px;
    }
    .mobile_account_delivery_item li.delivery_mob a i {
        width: 40px;
        height: 28px;
        background-size: auto 28px;
    }
    .mobile_account_delivery_item li.takeaway_mob a i {
        width: 40px;
        height: 28px;
        background-size: auto 28px;
    }
}

@media (max-width: 380px) {
    /* Header */
    .tbars header {
        height: 154px;
    }
    .mobile_mainacc_menutrigger {
        background-size: 12px auto;
        width: 32px;
    }
    .mobile_account_item>a {
        padding: 15px 32px 15px 35px;
    }
    .mobile_account_item>a>i {
        left: 5px;
        width: 22px;
        height: 22px;
    }
    .mobile_mainacc_menulist li a {
        padding: 15px 5px 15px 35px;
    }
    .mobile_mainacc_menulist .setting_menu_list_icon {
        left: 7px;
    }
    .mobile_account_item_login>a {
        padding: 15px 0 15px 35px;
    }
    .mobile_account_item_login>a>i {
        left: 5px;
        width: 22px;
        height: 22px;
    }
    .mobile_account_menu_click {
        padding: 15px 18px 18px 40px;
    }
    .mobile_account_menu_click i.mouse-myaccount {
        width: 28px;
        height: 20px;
        background-size: auto 20px;
        left: 5px;
    }
    .mobile_account_menu_click i.scotter-myaccount {
        width: 28px;
        height: 20px;
        background-size: auto 20px;
        left: 5px;
    }
    .mobile_account_menu_click i.takeaway-myaccount {
        width: 28px;
        height: 20px;
        background-size: auto 20px;
        left: 5px;
    }
    .mobile_account_menu_click i.arrow_myaccount {
        background-size: 12px auto;
        width: 32px;
    }
    .mobile_account_delivery_item a {
        padding-left: 40px;
    }
    .mobile_account_delivery_item li a i {
        left: 5px;
    }
    .mobile_account_delivery_item li.delivery_mob a i {
        width: 28px;
        height: 20px;
        background-size: auto 20px;
    }
    .mobile_account_delivery_item li.takeaway_mob a i {
        width: 35px;
        height: 28px;
        background-size: auto 28px;
    }
    .hcart_scrollarea {
        max-height: 257px;
    }
    .delivery_total_left {
        float: none;
        margin-bottom: 10px;
    }
    .cart_left {
        width: 100%;
    }
    .cart_right {
        text-align: left;
    }
    .cart_price {
        margin: 5px 0;
    }
    .ttsplitter span {
        z-index: 1;
    }
    .myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a, .small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a {
        font-size: 12px;
    }
    .sprct-feature-item-box .btn {
        font-size: 0.8rem;
    }
}

@media (max-width: 370px) {
    .promoContent .txt3 {
        margin-bottom: 0px;
        font-size: 0.6rem;
    }
    .myaccount-orders-block .order-info-block .checkout-block-header h4 {
        padding: 1rem 1rem;
        font-size: 1rem;
        float: left;
        width: 100%;
    }
    .myaccount-orders-block .order-info-block .checkout-block-header .order-status {
        width: 100%;
        font-size: 1rem;
        text-align: center;
    }
    .myaccount-orders-block .order-info-block .checkout-block-header .order-status:before {
        display: none;
    }
    .header-ordernow-btn {
        position: relative;
        padding: 0.5rem 0.7rem 0.6rem 0.5rem;
        font-size: 14px;
    }
    .header-ordernow-btn:before {
        content: inherit;
        background: none;
    }
    .prd-chosen-sub-item-left {
        width: 70.118056%;
    }
    .search-toggle {
        width: 28px;
    }
    .mobile_account_item .mobile_account_item_loggedin>a {
        padding: 9px 35px 8px 48px;
    }
    .delivery-datetime-modal .modal-body .delivery-address-block {
        padding: 1.8rem 0rem;
    }
    .calctrcart-footer .col-4.text-right {
        padding: 0;
    }
    .myaccount_separate {
        padding: 0px 2px;
    }
}